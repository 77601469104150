import {Injectable} from "@angular/core";
import {User} from "../../model/user.model";
import {BehaviorSubject} from "rxjs";

@Injectable({providedIn: 'root'})
export class ShellService {

  private userSource = new BehaviorSubject(null);
  currentUser = this.userSource.asObservable();

  private refreshVehicleSource = new BehaviorSubject(null);
  refreshVehicle = this.refreshVehicleSource.asObservable();

  constructor() {}

  setUser(user: User) {
    this.userSource.next(user);
  }

  setRefreshVehicle(refresh: boolean) {
    this.refreshVehicleSource.next(refresh);
  }
}
