<body>
  <div class="page-container">
    <mat-sidenav-container>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
      <mat-sidenav position="end" [opened]="openSidebar" (closed)="resetRouter()">
        <router-outlet name="side" (activate)="openSidebar=true" (deactivate)="openSidebar=false"></router-outlet>
      </mat-sidenav>
    </mat-sidenav-container>
  </div>
</body>
