import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root',
})
export class MessagingService {

  constructor(private snackBar: MatSnackBar) {}

  error = (message: string, action: string = null) =>
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['ERROR'],
    });

  confirm = (message: string, action: string = null) =>
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top',
      panelClass: ['CONFIRM'],
    });
}


