import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { Email } from '../types/email.type';
import { UserService } from "../register/user.service";
import { MessagingService } from "../components/messaging/messaging.service";
import { FormRequestState } from "../helpers/formHelper";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  resetForm: FormGroup;

  which: 'LOGIN' | 'RESET' = 'LOGIN';

  locked = false;
  resetState: FormRequestState = "EDITING";
  unauthorized = '';

  constructor(
    private auth: AuthService,
    private message: MessagingService,
    private router: Router,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        // tslint:disable-next-line:max-line-length
        Validators.pattern('^([^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+\\.)*[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+@[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+(\\.[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+)+$'),
      ]),
      password: new FormControl('', [
        Validators.required,
      ]),
    });

    this.resetForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        // tslint:disable-next-line:max-line-length
        Validators.pattern('^([^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+\\.)*[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+@[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+(\\.[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+)+$'),
      ]),
    });

    window.scrollTo(0, 0);
  }

  login() {
    const email: Email = this.loginForm.get('email').value;
    const password: string = this.loginForm.get('password').value;

    this.auth.login({email, password})
      .subscribe(
        () => this.router.navigate(['search', {outlets: {side: null}}]).catch(console.error),
        error => {
          if ("error" in
              error &&
              error.error !== null &&
              error.error.hasOwnProperty("Error") &&
              error.error.Error === "LOCKED") {
            this.unauthorized = 'Too many failed attempts. Try again in 5 minutes.';
          } else if (error.status === 403) {
            // Auth Provider rejected the credentials
            this.unauthorized = 'Your username or password are not valid. Try again.';
          } else {
            // There was am unexpected problem
            this.message.error("There was a problem logging you in. Please try again.");
          }
        },
        () => this.unauthorized = '',
      );
  }

  reset() {
    this.resetState = "INFLIGHT";
    this.resetForm.get('email').disable();
    this.userService.resetPassword(this.resetForm.get('email').value.toLowerCase())
      .subscribe(
        () => {},
        () => {
          this.message.error('There was a problem submitting your password reset request. Please try again.');
          this.resetForm.get('email').enable();
        },
        () => this.resetState = "ANSWERED",
      );
  }
}
