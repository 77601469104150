import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AARTParameterCodec } from '../../helpers/service.helper';
import { Vehicle } from '../../model/vehicle.model';
import { Part, Template } from '../../model/template.model';
import { MissingPart } from "../../model/missingPart.model";

@Injectable()
export class SearchService {
  private baseUrl = 'api';

  private _options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

  constructor(private _http: HttpClient) {
    this._options['responseType'] = 'json';
  }

  private static handleError(error: any) {
    return Promise.reject(error.error || error.message || error);
  }

  getMakes() {
    return this._http.get<string[]>(`${this.baseUrl}/vehicle/makes`)
      .pipe(
        catchError(SearchService.handleError),
      );
  }

  search(searchCriteria) {
    const options = {...this._options};
    const encoder = new AARTParameterCodec();

    options['params'] = new HttpParams({encoder});

    if (searchCriteria.make) {
      options['params'] = options['params'].append('make', searchCriteria.make);
    }

    if (searchCriteria.model) {
      options['params'] = options['params'].append('model', searchCriteria.model);
    }

    if (searchCriteria.year) {
      options['params'] = options['params'].append('year', searchCriteria.year);
    }

    return this._http.get<Vehicle[]>(`${this.baseUrl}/vehicle/search`, options)
      .pipe(
        catchError(SearchService.handleError),
      );
  }

  getTimes(vehicle) {

    const options = {...this._options};
    const encoder = new AARTParameterCodec();

    options['params'] = new HttpParams({encoder});

    if (vehicle.make) {
      options['params'] = options['params'].append('make', vehicle.make);
    }

    if (vehicle.description) {
      options['params'] = options['params'].append('description', vehicle.description);
    }

    if (vehicle.bodyStyle) {
      options['params'] = options['params'].append('bodyStyle', vehicle.bodyStyle);
    }

    if (vehicle.year) {
      options['params'] = options['params'].append('year', vehicle.year);
    }
    return this._http.get<Template>(`${this.baseUrl}/times`, options)
      .pipe(
        catchError(SearchService.handleError),
      );
  }

  getMissingTimes(descriptorId: number) {
    return this._http.get<MissingPart[]>(`${this.baseUrl}/times/missing/${descriptorId}`)
      .pipe(
        catchError(SearchService.handleError),
      );
  }
}
