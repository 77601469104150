<div class="columns-container" fxLayout="row" fxLayoutAlign="flex-start"
     fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-sm="10px" fxLayoutGap.sm="10px"
     ngClass.lt-sm="padding-narrow" ngClass.sm="padding-narrow">
  <div [fxFlex]="columnSize"
       [fxHide]="showResults && columnCount < 2"
       class="column"
       ngClass.lt-sm="margin-small"
       ngClass.sm="margin-small">
    <form [formGroup]="searchUsersForm">
      <h5 class="search-header">Search Users</h5>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Name or email</mat-label>
        <input formControlName="user" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Privilege</mat-label>
        <mat-select formControlName="privilege">
          <mat-option *ngFor="let privilege of privileges" [value]="privilege">{{privilege}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="search-button">
        <button (click)="searchUsers()" color="accent" mat-raised-button>SEARCH</button>
      </div>
    </form>
  </div>
  <div *ngIf="showResults"
       [fxFlex]="columnSize"
       class="column"
       ngClass.lt-sm="margin-small"
       ngClass.sm="margin-small">
    <div *ngIf="columnCount === 1" class="back-button">
      <button (click)="editSearchCriteria()" mat-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back to Search
      </button>
    </div>
    <div class="results-header">
      <h5>Users</h5>
      <div class="results-count">{{users.length}} found</div>
    </div>
    <cdk-virtual-scroll-viewport *ngIf="users.length" [itemSize]="71">
      <div (click)="showUpdateUser(user)" *cdkVirtualFor="let user of users"
           [class.selected]="user.id === editedUser?.id" class="results-container">
        <div class="data-holder">
          <div class="privilege">
            {{comma(user.privileges)}}
          </div>
          <h6 class="display-ellipsis">
            {{user.firstName}} {{user.lastName}}
          </h6>
          <div class="display-force-wrap">
            {{user.email}}
          </div>
        </div>
        <div class="initials-wrapper">
          <div class="user-initials">{{user.firstName.charAt(0)}}{{user.lastName.charAt(0)}}</div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <div *ngIf="!users.length" class="no-result">
      <h4>No results found</h4>
      <div class="no-result-text">Please consider reviewing your <br> filter criteria</div>

      <button (click)="editSearchCriteria()" *ngIf="columnCount === 1" color="accent" mat-raised-button>
        <mat-icon>search</mat-icon>
        Back to search
      </button>
    </div>
  </div>
</div>
