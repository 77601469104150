import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-upload-progress',
  template: `
    <h5>Saving...</h5>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  `,
  styleUrls: ['./upload-progress.component.scss']
})
export class UploadProgressComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<UploadProgressComponent>) { }

  ngOnInit() {
  }

  cancelUpload() {
    this.dialogRef.close('Cancel');
  }

}
