<div class="register-form">
  <div class="form-title">
    <h5>Register</h5>
  </div>
  <mat-divider></mat-divider>
  <form (ngSubmit)="register()" *ngIf="!userRegistered" [formGroup]="registerForm">
    <mat-form-field appearance="fill" color="accent">
      <mat-label>First Name</mat-label>
      <input formControlName="firstName" matInput required>
    </mat-form-field>
    <mat-error *ngIf="firstName.touched && firstName.hasError('required')">
      First Name is <strong>required</strong>
    </mat-error>
    <mat-form-field appearance="fill" color="accent">
      <mat-label>Last Name</mat-label>
      <input formControlName="lastName" matInput required>
    </mat-form-field>
    <mat-error *ngIf="lastName.touched && lastName.hasError('required')">
      Last Name is <strong>required</strong>
    </mat-error>
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input (input)="userAlreadyRegistered = false;" color="accent" formControlName="email" matInput required
             type="email">
    </mat-form-field>
    <mat-error *ngIf="email.touched && email.hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="email.hasError('pattern') && !email.hasError('required')">
      Please enter a valid email address
    </mat-error>

    <div *ngIf="userAlreadyRegistered" class="warn-box">
      <mat-icon>report_problem</mat-icon>
      This email has already been registered. Please try a different one or try to recover your password.
    </div>

    <button [disabled]="!registerForm.valid || pending" color="accent" mat-raised-button type="submit">
      REGISTER
    </button>
  </form>

  <div *ngIf="userRegistered" class="info-container">
    <h5>We're glad to have you with us</h5>
    <div class="inform-box">
      <mat-icon matPrefix>report_problem</mat-icon>
      <span>Check your emails to continue</span>
    </div>
    <p>
      You will have received an email from us with a link you can click to enter AART.
    </p>
  </div>
</div>
