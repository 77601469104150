import { Injectable } from '@angular/core';
import { BackdropTransition } from "./event-bus.types";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class EventBusService {

  public backdropTransition: Subject<BackdropTransition> = new Subject<BackdropTransition>();

  constructor() {}

  bdOpen = () => this.backdropTransition.next(BackdropTransition.OPENED);
  bdClose = () => this.backdropTransition.next(BackdropTransition.CLOSED);

}
