import { Component, Input } from '@angular/core';
import { DescriptorCore } from "../../model/descriptor.model";

@Component({
  selector: 'app-descriptor',
  templateUrl: './descriptor.component.html',
  styleUrls: ['./descriptor.component.scss'],
})
export class DescriptorComponent {
  @Input() descriptor: DescriptorCore;

  /**
   * @desc RevisionSearchResult.impactedDescriptorsCount includes the firstImpactedDescriptor
   * so this count is automatically decremented by 1 in the template.
   */
  @Input() count?: number;
}
