<div mat-dialog-title class="header">
  <div class="part-detail">
    {{partName}}
  </div>
  <h5>{{operationName}}</h5>
</div>
<div>
  <div class="time-header" fxLayout="row" fxLayoutAlign="center">
    <div class="published" fxFlex.xs="100%" fxFlex="30%">
      <div class="title">Revised Time</div>
      <div class="times">
        <h6>{{revisedTime | duration:'hours'}}</h6>
        <div class="time-minutes">{{revisedTime | duration:"hh:mm"}}</div>
      </div>
    </div>
  </div>
  <div class="title-border">
    <div class="title">
      Started with this vehicle
    </div>
    <div class="time-title">
      Published Time
    </div>
  </div>
  <div class="descriptor-container">
    <div class="descriptor-list  revision-descriptor" *ngIf="revisionDescriptor">
      <div class="description">
        <app-descriptor [descriptor]="revisionDescriptor"></app-descriptor>
        <div *ngIf="revisionDescriptor.publishedDate" class="published-date">Published on {{revisionDescriptor.publishedDate | date}}</div>
      </div>
      <div class="time">
        <ng-container *ngIf="revisionDescriptor.publishedTimeMinutes">
          <div>{{revisionDescriptor.publishedTimeMinutes | duration: 'hours'}}</div>
          <div class="time-minutes">{{revisionDescriptor.publishedTimeMinutes | duration: 'hh:mm'}}</div>
        </ng-container>
      </div>
    </div>
  </div>
  <form [formGroup]="descriptorForm">
    <div *ngIf="impactedDescriptors?.length" class="title-border">
      <div class="title">
        Also applies to vehicles similar to
      </div>
    </div>
    <div *ngIf="impactedDescriptors?.length" class="descriptor-container">
      <ul>
        <li *ngFor="let descriptor of impactedDescriptors; let i = index" formArrayName="impacted" class="descriptor-list">
          <div class="checkbox"><mat-checkbox [formControlName]="i" color="primary"></mat-checkbox></div>
          <div class="description">
            <app-descriptor [descriptor]="descriptor"></app-descriptor>
            <div *ngIf="descriptor.publishedDate" class="published-date">Published on {{descriptor.publishedDate | date}}</div>
          </div>
          <div class="time">
            <ng-container *ngIf="descriptor.publishedTimeMinutes">
              <div>{{descriptor.publishedTimeMinutes | duration: 'hours'}}</div>
              <div class="time-minutes">{{descriptor.publishedTimeMinutes | duration: 'hh:mm'}}</div>
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
    <div *ngIf="siblingDescriptors?.length" class="title-border">
      <div class="title">
        Extend this revision to vehicles similar to
      </div>
    </div>
    <div *ngIf="siblingDescriptors?.length" class="descriptor-container">
      <ul>
        <li *ngFor="let descriptor of siblingDescriptors; let idx = index" formArrayName="extended" class="descriptor-list">
          <div class="checkbox"><mat-checkbox [formControlName]="idx" color="primary"></mat-checkbox></div>
          <div class="description">
            <app-descriptor [descriptor]="descriptor"></app-descriptor>
            <div *ngIf="descriptor.publishedDate" class="published-date">Published on {{descriptor.publishedDate | date}}</div>
          </div>
          <div class="time">
            <ng-container *ngIf="descriptor.publishedTimeMinutes">
              <div>{{descriptor.publishedTimeMinutes | duration: 'hours'}}</div>
              <div class="time-minutes">{{descriptor.publishedTimeMinutes | duration: 'hh:mm'}}</div>
            </ng-container>
          </div>
        </li>
      </ul>
    </div>
  </form>
</div>
<mat-dialog-actions class="footer" fxLayout="row" fxLayoutAlign="flex-start">
  <div *ngIf="isMobile" fxLayout="column" fxLayoutGap="20px" class="bottom-20">
    <button color="accent" fxFlex="100%" mat-raised-button (click)="applyChanges()">
      <mat-icon>check_circle</mat-icon>
      Apply
    </button>
    <button fxFlex="100%" mat-button mat-dialog-close>Cancel</button>
  </div>
  <div *ngIf="!isMobile" fxLayoutGap="20px">
    <button fxFlex="0 1 (50% - 20px)" mat-button mat-dialog-close>Cancel</button>
    <button color="accent" fxFlex="0 1 50%" mat-raised-button (click)="applyChanges()">
      <mat-icon>check_circle</mat-icon>
      Apply
    </button>
  </div>
</mat-dialog-actions>
