<div class="columns-container" fxLayout="row" fxLayoutAlign="flex-start"
     fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-sm="10px" fxLayoutGap.sm="10px"
     ngClass.lt-sm="padding-narrow" ngClass.sm="padding-narrow">
  <div [fxFlex]="columnSize"
       [fxHide]="showResults && columnCount < 2"
       class="column"
       ngClass.lt-sm="margin-small"
       ngClass.sm="margin-small">
    <form [formGroup]="searchRevisionsForm">
      <h5 class="search-header">Search Revisions</h5>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Ownership</mat-label>
        <mat-select formControlName="ownership">
          <mat-option *ngFor="let ownership of ownerships" [value]="ownership">{{ownership}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statuses" [value]="status">{{status}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Part</mat-label>
        <mat-select (selectionChange)="partChanged($event.value)" formControlName="part">
          <mat-option *ngFor="let part of partCatalog" [value]="part">
            {{ part === null ? '' : part.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Operation</mat-label>
        <mat-select formControlName="operation">
          <mat-option *ngFor="let operation of this.operationsOfPart" [value]="operation">
            {{ operation === null ? '' : operation.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="search-button">
        <button (click)="searchRevisions()" color="accent" mat-raised-button>SEARCH</button>
      </div>
    </form>
  </div>
  <div *ngIf="showResults"
       [fxFlex]="columnSize"
       class="column"
       ngClass.lt-sm="margin-small"
       ngClass.sm="margin-small">
    <div *ngIf="columnCount === 1" class="back-button">
      <button (click)="editSearchCriteria()" mat-button>
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back to Search
      </button>
    </div>
    <div class="results-header">
      <h5>Revisions</h5>
      <div class="results-count">{{revisions.length}} found</div>
    </div>
    <cdk-virtual-scroll-viewport *ngIf="revisions.length" [itemSize]="133">
      <div (click)="openRevision(revision)" *cdkVirtualFor="let revision of revisions" class="results-container">
        <div class="data-holder">
          <div class="display-ellipsis part">
            {{revision.part}}
          </div>
          <div class="operation">
            {{revision.operation}}
          </div>
          <div class="display-ellipsis status-parent">
            <div [ngClass]="revision.status" class="status">
              <mat-icon>{{revision.status | revisionStatusIcon}}</mat-icon>
              <span class="status-child">{{revision.status | revisionStatus}}</span>
              <span class="transaction-date status-child">
                {{revision.status === 'IN_PROGRESS' ? 'since' : 'on'}} {{revision.lastTransactionDateTime | date}}
              </span>
            </div>
          </div>
          <app-descriptor [count]="revision.impactedDescriptorsCount"
                          [descriptor]="revision.firstImpactedDescriptor"></app-descriptor>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <div *ngIf="!revisions.length" class="no-result">
      <h4>No results found</h4>
      <div class="no-result-text">Please consider reviewing your <br> filter criteria</div>

      <button (click)="editSearchCriteria()" *ngIf="columnCount === 1" color="accent" mat-raised-button>
        <mat-icon>search</mat-icon>
        Back to search
      </button>
    </div>
  </div>
</div>
