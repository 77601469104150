<div class="current-user-container">
  <div class="data-holder">
    <div class="privilege">
      {{comma(updatedUser?.privileges)}}
    </div>
    <h6 class="display-ellipsis">
      {{updatedUser?.firstName}} {{updatedUser?.lastName}}
    </h6>
    <div class="display-force-wrap">
      {{updatedUser?.email}}
    </div>
  </div>
  <div class="initials-wrapper"><div class="user-initials">{{updatedUser?.firstName.charAt(0)}}{{updatedUser?.lastName.charAt(0)}}</div></div>
</div>
<div class="current-user-container">
  <h5>Permissions</h5>
</div>
<div class="privilege-container">
  <form [formGroup]="updateUserForm">
    <div>
      <mat-checkbox (change)="onPrivilegesChanged()" formControlName="expert">Expert</mat-checkbox>
    </div>
    <div>
      <mat-checkbox (change)="onPrivilegesChanged()" formControlName="reviewer">Reviewer</mat-checkbox>
    </div>
    <div>
      <mat-checkbox (change)="onPrivilegesChanged()" formControlName="admin">Admin</mat-checkbox>
    </div>
    <ng-container *ngIf="privilegesChanged">
    <div class="instructions">
      To apply changes, please enter your password and click SAVE CHANGES
    </div>
    <mat-form-field appearance="fill" color="primary" class="password-field">
      <mat-label>Password</mat-label>
      <input formControlName="password" matInput placeholder="Password" type="password">
    </mat-form-field>
    <div class="search-button">
      <button mat-raised-button color="accent" [disabled]="!privilegesChanged || !updateUserForm.valid" (click)="updateUser()">SAVE CHANGES</button>
    </div>
    </ng-container>
  </form>
</div>
