import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './user.service';
import { User } from '../model/user.model';
import { MessagingService } from '../components/messaging/messaging.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [UserService],
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;

  get firstName() {
    return this.registerForm.get('firstName');
  }

  get lastName() {
    return this.registerForm.get('lastName');
  }

  get email() {
    return this.registerForm.get('email');
  }

  userRegistered = false;
  userAlreadyRegistered = false;

  pending = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messagingService: MessagingService) {
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['',
        [
          Validators.required],
      ],
      lastName: ['',
        [
          Validators.required],
      ],
      email: ['',
        [
          Validators.required,
          // tslint:disable-next-line:max-line-length
          Validators.pattern('^([^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+\\.)*[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+@[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+(\\.[^@\\s\\."\'\\(\\)\\[\\]\\{\\}\\\\/,:;]+)+$'),
        ],
      ],
    });

    window.scrollTo(0, 0);
  }

  register() {
    this.pending = true;

    const user: User = {email: this.email.value, firstName: this.firstName.value, lastName: this.lastName.value};

    this.userService.register(user)
      .subscribe(
        () => this.userRegistered = true,
        error => {
          if (error.errorCode === 'ALREADY_REGISTERED') {
            this.userAlreadyRegistered = true;
          } else {
            this.messagingService.error('There was a problem registering you. Please try again');
          }
          this.pending = false;
        },
        () => this.pending = false,
      );
  }
}
