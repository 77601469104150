import { Pipe, PipeTransform } from "@angular/core";
import { fromUnixTime, format } from 'date-fns';

@Pipe({
  name: 'time',
  pure: true
})
export class TimePipe implements PipeTransform {
  transform(epoch: number) {
    return format(new Date(fromUnixTime(epoch)), 'p');
  }
}
