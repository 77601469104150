import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'revisionStatusIcon',
  pure: true
})
export class RevisionStatusIconPipe implements PipeTransform {

  transform(status) {
    // @ts-ignore
    switch (status) {
      case 'IN_PROGRESS': return 'rate_review';
      case 'SUBMITTED': return 'rate_review';
      case 'APPROVED': return 'check_circle';
      case 'DISCARDED': return 'delete';
      case 'ARCHIVED': return 'archive';
    }
  }
}
