import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './user.service';
import { MessagingService } from '../components/messaging/messaging.service';
import { FormRequestState } from "../helpers/formHelper";

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})

export class SetPasswordComponent implements OnInit {
  get password() {
    return this.setPasswordForm.get('password');
  }

  get confirmPassword() {
    return this.setPasswordForm.get('confirmPassword');
  }

  setPasswordForm: FormGroup;
  minCharacters = 14;
  nonce: string;
  email: string;
  name: string;
  expired = false;
  reused = false;

  formState: FormRequestState = 'EDITING';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private messagingService: MessagingService,
  ) {}

  static checkPasswords(group: FormGroup) {
    if (group.controls.password.value !== group.controls.confirmPassword.value) {
      group.controls.confirmPassword.setErrors({passwordMismatch: true});
    } else {
      group.controls.confirmPassword.setErrors(null);
    }
  }

  ngOnInit() {
    this.route.paramMap.subscribe(
      params => {
        this.nonce = params.get('nonce');
        this.email = params.get('email');
        this.name = params.get('name').split('+').join(' ');
        this.expired = new Date().getTime() > Number(params.get('expiry'));
      },
      () => this.messagingService.error('There is a problem with your link. Please check that it was copied exactly.'),
    );

    this.setPasswordForm = this.formBuilder.group({
        password: ['',
          [
            Validators.required,
            Validators.minLength(this.minCharacters),
          ],
        ],
        confirmPassword: ['',
          [
            Validators.required,
          ],
        ],
      }, {
        validator: SetPasswordComponent.checkPasswords,
      },
    );
  }

  // There is probably a 'better' way to do with this Rx.concatMap()
  setPassword = () => {
    this.password.disable();
    this.confirmPassword.disable();
    this.formState = 'INFLIGHT';

    return this.userService.setPassword({
      email: this.email,
      password: this.password.value,
      confirm: this.confirmPassword.value,
      nonce: this.nonce,
    }).subscribe(
      () => this.userService.login({email: this.email, password: this.password.value})
        .subscribe(
          () => this.router.navigate(['search']),
          () => this.messagingService.error('There was a problem authenticating you. Please try again.'),
        ),
      error => {
        if (error.status === 400) {
          switch (error.error.Error) {
            case "TOO_SHORT":
              this.password.setErrors({minlength: true});
              break;
            case "REUSED":
              this.reused = true;
              break;
          }

        } else if (error.status === 401) {
          // This case can happen if the reset token expires between clicking the link and clicking Set Password
          this.messagingService.error('Your reset link has expired. Please request a new one.');
        } else {
          // Generic error
          this.messagingService.error('There was a problem setting your password. Please try again');
        }

        this.password.enable();
        this.confirmPassword.enable();

        this.setPasswordForm.reset();
        this.setPasswordForm.markAsUntouched();
        this.setPasswordForm.markAsPristine();

        this.formState = 'EDITING';
      },
      () => this.formState = 'ANSWERED',
    )
  }
}
