<div class="columns-container" fxLayout="row" fxLayoutAlign="flex-start"
     fxLayoutGap.gt-sm="20px" fxLayoutGap.lt-sm="10px" fxLayoutGap.sm="10px"
     ngClass.lt-sm="padding-narrow" ngClass.sm="padding-narrow">
  <div [fxFlex]="columnSize"
       [fxHide]="!visible('SEARCH')"
       class="column"
       ngClass.lt-sm="margin-small"
       ngClass.sm="margin-small">
    <form [formGroup]="searchForm">
      <h5 class="search-header">Search</h5>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Make</mat-label>
        <mat-select formControlName="make">
          <mat-option *ngFor="let make of makes" [value]="make">{{make}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Model</mat-label>
        <input formControlName="model" matInput>
      </mat-form-field>
      <mat-form-field appearance="fill" color="accent">
        <mat-label>Year</mat-label>
        <mat-select formControlName="year">
          <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="paint-layer" fxLayout="row">
        <div class="paint-label">Paint Layer</div>
        <mat-button-toggle-group #groupLayers="matButtonToggleGroup" (change)="paintLayer = groupLayers.value"
                                 appearance="legacy"
                                 value="3">
          <mat-button-toggle value="1">
            <mat-icon>filter_1_24px</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="2">
            <mat-icon>filter_2_24px</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="3">
            <mat-icon>filter_3_24px</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <button (click)="search()" color="accent" mat-raised-button>SEARCH</button>
    </form>
  </div>
  <div
    [fxFlex]="columnSize"
    [fxHide]="!visible('VEHICLES')"
    class="column"
    ngClass.lt-sm="margin-small"
    ngClass.sm="margin-small">

    <ng-container
      *ngTemplateOutlet="backto; context: {parent: 'SEARCH', self: 'VEHICLES'}">
    </ng-container>

    <div class="title-header">
      <div class="criteria">
        {{comma(searchCriteria.make, searchCriteria.model, searchCriteria.year, searchCriteria.layer)}}-Layer Paint
      </div>
      <div class="list-title">
        Vehicles <span> {{vehicles.length}} found</span>
      </div>
    </div>

    <cdk-virtual-scroll-viewport *ngIf="vehicles.length" [itemSize]="74">
      <div (click)="openParts(vehicle)"
           *cdkVirtualFor="let vehicle of vehicles"
           [class.selected]="selectedVehicle && (vehicle.year === selectedVehicle.year && vehicle.modelAndVariant === selectedVehicle.modelAndVariant && vehicle.bodyStyle === selectedVehicle.bodyStyle)"
           class="vehicle-info-container">
        <div>
          <div class="display-ellipsis model">
            {{vehicle.modelAndVariant}}
          </div>
          <div class="display-ellipsis body-style">
            {{vehicle.bodyStyle}}
          </div>
          <div class="year">
            {{vehicle.year}}
          </div>
        </div>
        <div></div>
        <div [ngClass]="vehicle.make.replace(' ', '_')" class="make-logo"></div>
      </div>
    </cdk-virtual-scroll-viewport>

    <ng-container *ngIf="!vehicles.length">
      <mat-spinner *ngIf="searching"></mat-spinner>
      <div *ngIf="!searching" class="no-result">
        No results found
        <div class="no-result-text">Try reviewing your search</div>
        <div *ngIf="showBack('VEHICLES')" class="search-button">
          <button (click)="back('VEHICLES'); groupLayers.value='3';" color="accent" mat-raised-button>
            <mat-icon>search</mat-icon>
            BACK TO SEARCH
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div [fxFlex]="columnSize"
       [fxHide]="!visible('PARTS')"
       class="column"
       ngClass.lt-sm="margin-small"
       ngClass.sm="margin-small">
    <ng-container *ngTemplateOutlet="backto; context: {parent: 'VEHICLES', self: 'PARTS'}"></ng-container>

    <div class="vehicle-info-container selected-vehicle">
      <div>
        <div class="model">
          {{selectedVehicle?.modelAndVariant}}
        </div>
        <div class="body-style">
          {{selectedVehicle?.bodyStyle}}
        </div>
        <div class="year">
          {{selectedVehicle?.year}}
        </div>
      </div>
      <div></div>
      <div [ngClass]="selectedVehicle?.make.replace(' ', '_')" class="make-logo"></div>
    </div>

    <mat-spinner *ngIf="searchingParts"></mat-spinner>

    <section *ngIf="!searchingParts" class="scroll">
      <div *ngIf="template !== null" class="vehicle-description">
        <div>Showing parts for vehicles similar to</div>
        <app-descriptor [descriptor]="template"></app-descriptor>
      </div>
      <div [class.filtered]="descriptionFilter.value || sectionFilter !== 'All Sections'" class="title-header">
        <div class="list-title">
          Parts <span *ngIf="descriptionFilter.value || sectionFilter !== 'All Sections'; else showAllParts">
              Showing {{(template?.parts | filter:'PARTS':descriptionFilter.value:sectionFilter).length}}
          out of {{template?.parts?.length}}
            </span>
          <ng-template #showAllParts>
            <span>{{template !== null ? template?.parts.length + ' found' : 'Nothing found'}}</span>
          </ng-template>
        </div>
      </div>

      <div *ngIf="template !== null" class="parts-container">
        <form [formGroup]="partsFilterForm">
          <mat-form-field appearance="fill" class="margin-top-0" color="accent">
            <mat-label>Filter parts</mat-label>
            <input (input)="selectedPart !== null && closeTimes()" formControlName="descriptionFilter" matInput>
            <mat-icon (click)="descriptionFilter.value && descriptionFilter.setValue('')" matSuffix>
              {{descriptionFilter.value ? 'cancel' : 'filter_list'}}
            </mat-icon>
          </mat-form-field>
          <mat-form-field appearance="fill" color="accent">
            <mat-label>Section</mat-label>
            <mat-select (valueChange)="selectedPart !== null && closeTimes()" formControlName="sectionFilter">
              <mat-option *ngFor="let section of sections" [value]="section">{{section}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <div
        *ngIf="template?.parts.length && (template.parts | filter:'PARTS':descriptionFilter.value:sectionFilter).length !== 0 "
        class="part-list">
        <div (click)="openTimes(part)"
             *ngFor="let part of template.parts | filter:'PARTS':descriptionFilter.value:sectionFilter"
             [class.selected]="part.id === selectedPart?.id"
             class="parts-detail">
          <div class="detail-container">
            <div class="section"> {{part?.section}}</div>
            <div class="description"> {{part?.description}}</div>
          </div>
          <div class="count-times">
            {{part?.paintTimes?.length + part?.rrTimes?.length}} times
          </div>
        </div>
      </div>
      <div
        *ngIf="template?.parts.length && (template?.parts | filter:'PARTS':descriptionFilter.value:sectionFilter).length === 0"
        class="no-result">
        <h4 class="margin-bottom-20">No results found</h4>
        <div *ngIf="!currentUser?.privileges.includes('EXPERT')" class="no-result-text">Try reviewing your filters</div>
        <button (click)="clearFilters()" color="accent" mat-raised-button>
          <mat-icon>cancel</mat-icon>
          CLEAR FILTERS
        </button>
      </div>
      <div *ngIf="selectedVehicle && template === null" class="no-result">
        <h4>No results found</h4>
        <div class="no-result-text">Try selecting another vehicle</div>
        <button (click)="closeParts()" *ngIf="showBack('PARTS')" color="accent" mat-raised-button>
          <mat-icon>search</mat-icon>
          BACK TO VEHICLES
        </button>
      </div>

      <div *ngIf="template !== null && currentUser?.privileges.includes('EXPERT')" class="padding-top-0">
        <div class="no-result-text">If a time is missing on this list, please consider adding it.</div>
        <div class="no-result-button">
          <button (click)="addMissingTime()" color="accent" mat-raised-button>
            <mat-icon>add_box</mat-icon>
            ADD MISSING TIME
          </button>
        </div>
      </div>
    </section>
  </div>

  <div [fxFlex]="columnSize"
       [fxHide]="!visible('TIMES')"
       class="column"
       ngClass.lt-sm="margin-small"
       ngClass.sm="margin-small">
    <ng-container
      *ngTemplateOutlet="backto; context: {parent: 'PARTS', self: 'TIMES'}">
    </ng-container>

    <div class="part-detail-title">
      <div class="section"> {{selectedPart?.section}}</div>
      <div class="description"> {{selectedPart?.description}}</div>
    </div>

    <div class="title-header">
      <div class="list-title">
        Replace
        <span>{{selectedPart?.rrTimes?.length}} found</span>
      </div>
    </div>

    <cdk-virtual-scroll-viewport
      *ngIf="selectedPart?.rrTimes?.length" [itemSize]="65" class="time-list replace">
      <div (click)="showRevision(repairTime, template.descriptorId)"
           *cdkVirtualFor="let repairTime of selectedPart.rrTimes"
           [class.selected]="selectedRepairTime === repairTime"
           class="time-detail">
        <div class="description"> {{repairTime.description}}</div>
        <div></div>
        <div class="time">
          {{repairTime.repairTimeMinutes | duration:"hours"}}
        </div>
      </div>
    </cdk-virtual-scroll-viewport>

    <div class="title-header">
      <div class="list-title">
        Paint
        <div (click)="toggleDetailPaintLayers = !toggleDetailPaintLayers" [class.selected]="toggleDetailPaintLayers"
             class="paint-layer-icon">
          <mat-icon>{{detailPaintLayer === '3' ? 'filter_3' : detailPaintLayer === '2' ? 'filter_2' : 'filter_1'}}</mat-icon>
          <mat-card class="layers-select-box">
            <mat-card-actions>
              <button (click)="detailPaintLayer = '1'" mat-button>1-Layer paint
                <mat-icon>filter_1</mat-icon>
              </button>
              <button (click)="detailPaintLayer = '2'" mat-button>2-Layer paint
                <mat-icon>filter_2</mat-icon>
              </button>
              <button (click)="detailPaintLayer = '3'" mat-button>3-Layer paint
                <mat-icon>filter_3</mat-icon>
              </button>
            </mat-card-actions>
          </mat-card>
        </div>
        <span>{{selectedPart?.paintTimes?.length}} found</span>
      </div>
    </div>

    <cdk-virtual-scroll-viewport
      *ngIf="selectedPart?.paintTimes?.length" [itemSize]="65" class="time-list paint">
      <div *cdkVirtualFor="let paintTime of selectedPart.paintTimes"
           class="time-detail no-hover">
        <div class="description">
          {{paintTime.description}}
        </div>
        <div></div>
        <div class="time">
          {{(detailPaintLayer === '3' ? paintTime.layer3TimeMinutes : detailPaintLayer === '2' ? paintTime.layer2TimeMinutes : paintTime.layer1TimeMinutes) | duration:"hours"}}
        </div>
      </div>
    </cdk-virtual-scroll-viewport>

    <div *ngIf="currentUser?.privileges.includes('EXPERT')">
      <div class="no-result-text">If a time is missing on this list, please consider adding it.</div>
      <div class="no-result-button">
        <button (click)="addMissingTime(selectedPart.description)" color="accent" mat-raised-button>
          <mat-icon>add_box</mat-icon>
          ADD MISSING TIME
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #backto let-on="on" let-parent="parent" let-self="self">
  <div (click)="back(self)" *ngIf="showBack(self)" class="back-button">
    <button mat-button>
      <mat-icon>keyboard_arrow_left</mat-icon>
      Back to {{parent}}
    </button>
  </div>
</ng-template>
