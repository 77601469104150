/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lightbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/cdk/drag-drop";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "./lightbox.component";
import * as i12 from "./lightbox-ref";
import * as i13 from "./lightbox.tokens";
var styles_LightboxComponent = [i0.styles];
var RenderType_LightboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LightboxComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 0, name: "fadeOut", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "fadeIn", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "* => fadeIn", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }] } });
export { RenderType_LightboxComponent as RenderType_LightboxComponent };
function View_LightboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["imageElement", 1]], null, 0, "img", [], [[24, "@fade", 0], [8, "src", 4]], [[null, "load"], [null, "wheel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = ((_co.loading = false) !== false);
        ad = (pd_0 && ad);
    } if (("wheel" === en)) {
        var pd_1 = (_co.wheelScroll($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading ? "fadeOut" : "fadeIn"); var currVal_1 = i1.ɵinlineInterpolate(2, "/servlet/image?revisionId=", _co.image.revisionId, "&stepNumber=", _co.image.stepNumber, ""); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LightboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reset() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(2, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["youtube_searched_for"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoomIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(5, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["zoom_in"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.zoomOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["zoom_out"]))], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).inline; var currVal_1 = (((i1.ɵnov(_v, 2).color !== "primary") && (i1.ɵnov(_v, 2).color !== "accent")) && (i1.ɵnov(_v, 2).color !== "warn")); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 5).inline; var currVal_3 = (((i1.ɵnov(_v, 5).color !== "primary") && (i1.ɵnov(_v, 5).color !== "accent")) && (i1.ɵnov(_v, 5).color !== "warn")); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵnov(_v, 8).inline; var currVal_5 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); _ck(_v, 7, 0, currVal_4, currVal_5); }); }
function View_LightboxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(1, 114688, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LightboxComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { imageElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "lightbox-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 7, "div", [["cdkDrag", ""], ["class", "img-container cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i9.ɵb, null, [i9.CdkDrag]), i1.ɵdid(4, 4866048, null, 3, i9.CdkDrag, [i1.ElementRef, [3, i9.CDK_DROP_LIST], i7.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i9.CDK_DRAG_CONFIG, [2, i10.Directionality], i9.DragDrop, i1.ChangeDetectorRef], { freeDragPosition: [0, "freeDragPosition"], disabled: [1, "disabled"] }, null), i1.ɵqud(603979776, 2, { _handles: 1 }), i1.ɵqud(603979776, 3, { _previewTemplate: 0 }), i1.ɵqud(603979776, 4, { _placeholderTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxComponent_1)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "div", [["class", "tool-belt"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxComponent_2)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(14, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["close"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LightboxComponent_3)), i1.ɵdid(17, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.initialImagePosition; var currVal_3 = _co.isMobile; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = (_co.image.revisionId && _co.image.stepNumber); _ck(_v, 9, 0, currVal_4); var currVal_5 = !_co.isMobile; _ck(_v, 12, 0, currVal_5); _ck(_v, 14, 0); var currVal_8 = _co.loading; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).disabled; var currVal_1 = i1.ɵnov(_v, 4)._dragRef.isDragging(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_6 = i1.ɵnov(_v, 14).inline; var currVal_7 = (((i1.ɵnov(_v, 14).color !== "primary") && (i1.ɵnov(_v, 14).color !== "accent")) && (i1.ɵnov(_v, 14).color !== "warn")); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
export function View_LightboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lightbox", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LightboxComponent_0, RenderType_LightboxComponent)), i1.ɵdid(1, 114688, null, 0, i11.LightboxComponent, [i1.Renderer2, i12.LightboxRef, i13.IMAGE_TOKEN], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LightboxComponentNgFactory = i1.ɵccf("app-lightbox", i11.LightboxComponent, View_LightboxComponent_Host_0, {}, {}, []);
export { LightboxComponentNgFactory as LightboxComponentNgFactory };
