import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MessagingService } from "../../components/messaging/messaging.service";
import { Router } from "@angular/router";
import { ShellService } from "./shell.service";
import { User } from "../../model/user.model";
import { UserService } from "../../register/user.service";
import { UserUpdateService } from "../users/user-update.service";
import { AuthService } from "../../auth/auth.service";
import { EventBusService } from "../../helpers/event-bus/event-bus.service";

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShellComponent implements OnInit {
  currentYear = new Date().getFullYear();
  openSidebar = false;
  searchVehicles = true; // Default page
  searchRevisions = false;
  editUsers = false;
  currentUser: User;

  constructor(
    private auth: AuthService,
    public eventBus: EventBusService,
    private messagingService: MessagingService,
    private router: Router,
    private shellService: ShellService,
    private userService: UserService,
    private userUpdateService: UserUpdateService,
  ) { }

  ngOnInit() {
    this.searchVehicles = true;
    this.shellService.setRefreshVehicle(false);
    if (this.router.url.indexOf('users') !== -1) {
      this.searchVehicles = false;
      this.editUsers = true;
    } else if (this.router.url.indexOf('revisions') !== -1) {
      this.searchVehicles = false;
      this.searchRevisions = true;
    }
    this.userService.getCurrentUser()
      .subscribe(
        user => {
          this.currentUser = user;
          this.userUpdateService.currentUser = user;
          this.shellService.setUser(user);
        },
        (e) => console.error(`Error loading user: ${e}`),
      );

    if (this.openSidebar) {
      this.resetRouter();
    }
  }

  search() {
    this.searchVehicles = true;
    this.searchRevisions = false;
    this.editUsers = false;
    this.router.navigate(['search']).catch();
  }

  searchRevisionsClicked() {
    this.searchVehicles = false;
    this.searchRevisions = true;
    this.editUsers = false;
    this.router.navigate(['search/revisions']).catch();
  }

  searchUsers() {
    this.searchVehicles = false;
    this.searchRevisions = false;
    this.editUsers = true;
    this.router.navigate(['search/users']).catch();
  }

  resetRouter() {
    // Reset the named outlet to null to remove the special url from the route
    this.router.navigate(['search', {outlets: {side: null}}]).catch();
    this.openSidebar = false;
  }

  logout = () => this.auth.logout()
    .subscribe(
      () => window.location.replace("/welcome"),
      () => this.messagingService.error('There was a problem logging you out. Please try again.'),
    )
}
