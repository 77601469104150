import { Injectable } from "@angular/core";

@Injectable({providedIn: 'root'})
export class SidePanelBus {

  descriptorId: number;
  operationId: number;
  revisionId: number;
  templateId: number;
  templateRepairTimeId: number;
  operation: string;
  part: string;

  clear = () => {
    this.descriptorId = null;
    this.operationId = null;
    this.revisionId = null;
    this.templateId = null;
    this.templateRepairTimeId = null;
    this.operation = null;
    this.part = null;
  }
}
