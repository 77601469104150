import { MatSnackBar } from "@angular/material/snack-bar";
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class MessagingService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        this.error = (message, action = null) => this.snackBar.open(message, action, {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['ERROR'],
        });
        this.confirm = (message, action = null) => this.snackBar.open(message, action, {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: ['CONFIRM'],
        });
    }
}
MessagingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagingService_Factory() { return new MessagingService(i0.ɵɵinject(i1.MatSnackBar)); }, token: MessagingService, providedIn: "root" });
