import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Template } from "../../model/template.model";
import { SearchService } from "../search/search.service";
import { MessagingService } from '../../components/messaging/messaging.service';
import { FormBuilder, FormGroup } from "@angular/forms";
import { MissingOperation, MissingPart } from "../../model/missingPart.model";
import { EditRevisionComponent } from "../revision/edit-revision.component";
import { User } from "../../model/user.model";
import { RevisionService } from "../revision/revision.service";

@Component({
  selector: 'app-add-missing-time',
  templateUrl: './add-missing-time.component.html',
  styleUrls: ['./add-missing-time.component.scss'],
  providers: [SearchService, RevisionService],
  encapsulation: ViewEncapsulation.None,
})
export class AddMissingTimeComponent implements OnInit {

  template: Template;
  partsFilterForm: FormGroup;

  selectedOperation: MissingOperation = null;

  missingParts: MissingPart[] = [];

  selectedPart = null;

  currentUser: User = null;

  get descriptionFilter() {
    return this.partsFilterForm.get('descriptionFilter');
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AddMissingTimeComponent>,
              private searchService: SearchService,
              private fb: FormBuilder,
              public matDialog: MatDialog,
              private revisionService: RevisionService,
              private messagingService: MessagingService) { }

  ngOnInit() {
    this.template = this.data.template;
    this.currentUser = this.data.currentUser;

    this.partsFilterForm = this.fb.group({
      descriptionFilter: this.data.descriptionFilter ? this.data.descriptionFilter : '',
    });

    this.getMissingTimes();
  }

  getMissingTimes() {
    this.searchService.getMissingTimes(this.template.descriptorId)
      .subscribe(
        (missingParts) => this.missingParts = missingParts,
        () => this.messagingService.error('There was a problem looking for missing times. Please try again.'),
      )
  }

  startRevision() {
    const dialogRef = this.matDialog.open(EditRevisionComponent, {
      maxWidth: '90vw',
      minWidth: '280px',
      width: '748px',
      maxHeight: '100vh',
      autoFocus: false,
      disableClose: true,
      data: {
        partName: this.selectedPart.description,
        operationName: this.selectedOperation.description,
        operationId: this.selectedOperation.id,
        templateId: this.template.leafRepairTemplateId,
        expertId: this.currentUser.id,
        descriptorId: this.template.descriptorId,
        revisionId: (this.selectedOperation.existingRevision && this.selectedOperation.existingRevision.status ===
                     'IN_PROGRESS' &&
                     this.selectedOperation.existingRevision.expertId === this.currentUser.id)
                    ? this.selectedOperation.existingRevision.id
                    : null,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'Success') {
        this.dialogRef.close({status: 'Success', revisionId: result.revisionId});
      }
    });

  }
}
