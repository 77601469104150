import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  confirmationMessage = '';
  explanationText = '';
  actionButtonText = 'OK';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.confirmationMessage = this.data.message;
    this.explanationText = this.data.explanationText;
    this.actionButtonText = this.data.actionButton;
  }

}
