import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search/search.component';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterPipe } from '../pipes/filter.pipe';
import { JwtRefreshModule } from '../jwt/jwt-refresh.module';
import { ShellComponent } from './shell/shell.component';
import { UsersComponent } from './users/users.component';
import { OrderByPipe } from '../pipes/orderBy.pipe';
import { UpdateUserComponent } from './users/update-user.component';
import { UserUpdateService } from './users/user-update.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RevisionComponent } from './revision/revision.component';
import { RevisionsComponent } from './revisions/revisions.component';
import { SidePanelBus } from './revision/side-panel-bus.service';
import { DurationPipe } from '../pipes/duration.pipe';
import { EditRevisionComponent } from './revision/edit-revision.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { RevisionsService } from './revisions/revisions.service';
import { DatePipe } from '../pipes/date.pipe';
import { MatChipsModule } from "@angular/material/chips";
import { MatMenuModule } from "@angular/material/menu";
import { MatExpansionModule } from "@angular/material/expansion";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RevisionStatusPipe } from "../pipes/revisionStatus.pipe";
import { RevisionStatusIconPipe } from "../pipes/revisionStatusIcon.pipe";
import { UploadProgressComponent } from "./upload-progress/upload-progress.component";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/typings/radio";
import { ConfirmationComponent } from "../components/confirmation/confirmation.component";
import { TimePipe } from "../pipes/time.pipe";
import { OverlayModule } from '@angular/cdk/overlay';
import { LightboxComponent } from "../lightbox/lightbox.component";
import { LightboxService } from "../lightbox/lightbox.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PluralizePipe } from "../pipes/pluralize.pipe";
import { DescriptorComponent } from "../components/descriptor/descriptor.component";
import { AddMissingTimeComponent } from './add-missing-time/add-missing-time.component';
import { SelectImpactedDescriptorsComponent } from "./select-impacted-descriptors/select-impacted-descriptors.component";
import { WarningMessageComponent } from "../components/warning-message/warning-message.component";

@NgModule({
  declarations: [
    AddMissingTimeComponent,
    DatePipe,
    DescriptorComponent,
    DurationPipe,
    EditRevisionComponent,
    FilterPipe,
    LightboxComponent,
    OrderByPipe,
    PluralizePipe,
    RevisionComponent,
    RevisionsComponent,
    RevisionStatusIconPipe,
    RevisionStatusPipe,
    SearchComponent,
    SearchComponent,
    SelectImpactedDescriptorsComponent,
    ShellComponent,
    TimePipe,
    UpdateUserComponent,
    UploadProgressComponent,
    UsersComponent,
    WarningMessageComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    DragDropModule,
    FlexLayoutModule,
    JwtRefreshModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    OverlayModule,
    ReactiveFormsModule,
    ScrollingModule,
    SearchRoutingModule,
  ],
  entryComponents: [
    AddMissingTimeComponent,
    ConfirmationComponent,
    EditRevisionComponent,
    LightboxComponent,
    SelectImpactedDescriptorsComponent,
    UploadProgressComponent,
    WarningMessageComponent,
  ],
  providers: [
    LightboxService,
    SidePanelBus,
    RevisionsService,
    UserUpdateService,
  ],
})
export class SearchModule {}
