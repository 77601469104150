import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { RevisionDescriptor } from "../../model/descriptor.model";
import { RevisionService } from "../revision/revision.service";
import { MessagingService } from "../../components/messaging/messaging.service";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { WarningMessageComponent } from "../../components/warning-message/warning-message.component";
import { LogRequestSplittingTime } from "../../model/logRequestSplittingTime";

@Component({
  selector: 'app-select-impacted-descriptors',
  templateUrl: './select-impacted-descriptors.component.html',
  styleUrls: ['./select-impacted-descriptors.component.scss'],
  providers: [RevisionService],
})
export class SelectImpactedDescriptorsComponent implements OnInit {
  isMobile = false;
  partName: string;
  operationName: string;
  revisedTime: number;
  impactedDescriptors: RevisionDescriptor[] = [];
  siblingDescriptors: RevisionDescriptor[] = [];
  revisionDescriptor: RevisionDescriptor;
  descriptorForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SelectImpactedDescriptorsComponent>,
    public warningDialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private revisionService: RevisionService,
    private messagingService: MessagingService,
    private fb: FormBuilder,
  ) {}

  get impacted() {return this.descriptorForm.get('impacted') as FormArray; }

  get extended() {return this.descriptorForm.get('extended') as FormArray; }

  ngOnInit() {
    this.partName = this.data.partName;
    this.operationName = this.data.operationName;
    this.revisedTime = this.data.revisedTime;

    this.descriptorForm = this.fb.group({
      impacted: this.fb.array([]),
      extended: this.fb.array([]),
    });

    this.revisionService.getImpactedDescriptorsForTemplate(this.data.templateId, this.data.operationId)
      .subscribe(
        descriptors => {
          this.impactedDescriptors = descriptors;
          this.revisionDescriptor = this.impactedDescriptors.filter((descriptor: RevisionDescriptor) =>
            descriptor.descriptorId === this.data.originalDescriptorId)[0];

          // Remove the revision descriptor from the list of impacted descriptors
          this.impactedDescriptors = this.impactedDescriptors.filter((descriptor: RevisionDescriptor) =>
            descriptor.descriptorId !== this.data.originalDescriptorId);

          this.impactedDescriptors.forEach((impacted) => {
            this.impacted.push(new FormControl(true))
          });
        },
        () => this.messagingService.error('There was an error loading the impacted descriptors. Please try again.'),
      );

    this.revisionService.getSiblingDescriptors(this.data.templateId, this.data.operationId)
      .subscribe(
        descriptors => this.siblingDescriptors = descriptors,
        () => this.messagingService.error('There was an error loading the sibling descriptors. Please try again.'),
        () => this.addCheckboxes(),
      );

    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe(result => {
        this.isMobile = result.breakpoints[Breakpoints.HandsetPortrait];
      });
  }

  addCheckboxes() {
    this.siblingDescriptors.forEach((sibling) => {
      this.extended.push(new FormControl(false))
    });
  }

  applyChanges() {
    let selectedDescriptors = this.impacted.controls.map((checkbox, index) => checkbox.value
                                                                              ? this.impactedDescriptors[index]
                                                                              : null)
      .filter(value => value !== null);
    selectedDescriptors = selectedDescriptors.concat(this.extended.controls.map((checkbox, index) =>
      checkbox.value ? this.siblingDescriptors[index] : null).filter(value => value !== null));

    // Add the original revision descriptor
    selectedDescriptors = selectedDescriptors.concat(this.revisionDescriptor);
    const selectedDescriptorsIds = selectedDescriptors.map(descriptor => descriptor.descriptorId);

    const selectedImpacted = this.impacted.controls.filter((checkbox) => checkbox.value);
    const selectedSiblings = this.extended.controls.filter((checkbox) => checkbox.value);
    if (selectedImpacted.length !== this.impactedDescriptors.length ||
        (selectedSiblings.length > 0 && selectedSiblings.length !== this.siblingDescriptors.length)) {
      // We do not support un-selecting from the first list of descriptors yet
      // The user can only select all the list of siblings or none
      this.revisionService.logRequestSplittingTime(
        {
          originalDescriptorId: this.revisionDescriptor.descriptorId,
          templateId: this.data.templateId,
          operationId: this.data.operationId,
          selectedDescriptorIds: selectedDescriptorsIds,
        } as LogRequestSplittingTime,
      ).subscribe(
        () => {
          this.warningDialog.open(WarningMessageComponent, {
            width: '250px',
            data: {
              message: "The site doesn't support that feature yet and has recorded your request for further analysis",
            },
          });
        },
        () => this.messagingService.error('There was an error recording your request. Please try again.'),
      );
    } else {
      this.dialogRef.close({status: 'Success', selected: selectedDescriptors});
    }
  }
}
