<div class="page-container">
  <form (ngSubmit)="setPassword()" [formGroup]="setPasswordForm">

    <mat-card *ngIf="!expired" class="register-card mat-elevation-z2">
      <h5>Set Password</h5>
      <h4>Welcome back, {{name}}!</h4>
      <h4 class="gray-title">Your password must include:</h4>
      <ul>
        <li [class.valid]="!password.errors">
          At least {{ minCharacters }} characters
        </li>
      </ul>

      <mat-form-field appearance="fill" class="password-field" color="accent">
        <mat-label>Password</mat-label>
        <mat-pass-toggle-visibility #togglePassword matSuffix></mat-pass-toggle-visibility>
        <input [type]="togglePassword.type" autocomplete="new-password" formControlName="password" matInput
               placeholder="Password">
        <mat-error *ngIf="password.errors">
          Must be at least {{ minCharacters }} characters long.
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="password-field" color="accent">
        <mat-label>Confirm Password</mat-label>
        <mat-pass-toggle-visibility #toggleConfirm matSuffix></mat-pass-toggle-visibility>
        <input [type]="toggleConfirm.type" autocomplete="new-password" formControlName="confirmPassword" matInput
               placeholder="Confirm Password">
        <mat-error *ngIf="confirmPassword.hasError('passwordMismatch')">
          Your passwords do not match
        </mat-error>
      </mat-form-field>

      <mat-card-actions align="center">
        <button [disabled]="!setPasswordForm.valid || formState === 'INFLIGHT'"
                color="accent"
                mat-raised-button
                type="submit">
          <span *ngIf="formState === 'EDITING'">SET PASSWORD</span>
          <mat-spinner *ngIf="formState === 'INFLIGHT'" [diameter]="25"></mat-spinner>
        </button>
      </mat-card-actions>

      <div *ngIf="reused" class="warn-box">
        <mat-icon matPrefix>report_problem</mat-icon>
        You cannot reuse previous passwords. Please choose a different one.
      </div>
    </mat-card>

    <mat-card *ngIf="expired" class="register-card mat-elevation-z2">
      <h5>Your link has expired!</h5>
      <p>
        <a href="/welcome/(side:register)">Click here to register again.</a>
      </p>
    </mat-card>
  </form>
</div>



