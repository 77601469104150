import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from "../../model/user.model";
import { UserUpdateService } from "./user-update.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../register/user.service";
import { PrivilegeUpdate } from "../../model/privilege-update.model";
import { MessagingService } from "../../components/messaging/messaging.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss'],
})
export class UpdateUserComponent implements OnInit, OnDestroy {
  updatedUser: User;
  updateUserForm: FormGroup;
  privileges: string[] = [];
  privilegesChanged = false;
  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private messagingService: MessagingService,
    private router: Router,
    private userService: UserService,
    private userUpdateService: UserUpdateService,
  ) {
  }

  ngOnInit() {
    this.subscription = this.userUpdateService.updatedUserObservable.subscribe(user => this.updatedUser = user);
    this.updateUserForm = this.fb.group({
      admin: false,
      expert: false,
      reviewer: false,
      password: ['', Validators.required],
    });

    if (this.updatedUser) {
      this.privileges = this.updatedUser.privileges;
      this.initCheckboxes();
    }
  }

  ngOnDestroy() {
    this.userUpdateService.setUpdatedUser(null);
    this.subscription.unsubscribe();
  }

  initCheckboxes() {
    this.privileges.forEach(privilege => this.updateUserForm.get(privilege.toLowerCase()).setValue(true));
  }

  onPrivilegesChanged() {
    this.privilegesChanged = !(this.updateUserForm.get('admin').value && this.privileges.includes('ADMIN') &&
      this.updateUserForm.get('expert').value && this.privileges.includes('EXPERT') &&
      this.updateUserForm.get('reviewer').value && this.privileges.includes('REVIEWER'));
  }

  updateUser() {
    if (this.updateUserForm.valid) {
      this.privileges = [];
    }
    if (this.updateUserForm.get('admin').value) {
      this.privileges.push('ADMIN');
    }
    if (this.updateUserForm.get('expert').value) {
      this.privileges.push('EXPERT');
    }
    if (this.updateUserForm.get('reviewer').value) {
      this.privileges.push('REVIEWER');
    }

    this.userService.updateUser({
      userId: this.updatedUser.id,
      privileges: this.privileges,
      password: this.updateUserForm.get('password').value,
    } as PrivilegeUpdate)
      .subscribe(
        () => {
          this.updatedUser.privileges = this.privileges;
          this.userUpdateService.setUpdatedUser(this.updatedUser);
          this.router.navigate(['search', {outlets: {primary: 'users', side: null}}]).catch();
        },
        () => this.messagingService.error('There was a problem updating the user. Please try again.'),
      );
  }

  comma = (terms: string[]) => terms ? terms.filter(String).join(', ') : null;
}
