import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessagingService } from "../components/messaging/messaging.service";

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private messagingService: MessagingService,
    private router: Router,
  ) {}

  intercept = (
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> =>
    next.handle(request)
      .pipe(
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.messagingService.error('Your session has expired. Please log in again.');
            this.router.navigate(['welcome']).catch(() => window.location.replace('/'));
            return of(null);
          }
          throw error;
        }),
      );
}
