import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MessagingService } from "../../components/messaging/messaging.service";
import { User } from "../../model/user.model";
import { Router } from "@angular/router";
import { BreakpointObserver } from "@angular/cdk/layout";
import { RevisionsService } from "./revisions.service";
import { RevisionSearchResult } from "../../model/revisionSearchResult.model";
import { ShellService } from "../shell/shell.service";
import { SidePanelBus } from '../revision/side-panel-bus.service';
import { PartCatalogItem } from "../../model/partCatalog.model";
import { OperationCatalogItem } from "../../model/operationCatalog.model";
import { AARTMediaQueries, CalculateColumnSize, ColumnSizeCSS, CountColumns } from "../../helpers/breakpoints";

@Component({
  selector: 'app-revisions',
  templateUrl: './revisions.component.html',
  styleUrls: ['./revisions.component.scss'],
})
export class RevisionsComponent implements OnInit {
  searchRevisionsForm: FormGroup;
  ownerships: string[] = ['All Revisions', 'Only My Revisions'];
  statuses: string[] = ['All Status', 'In Progress', 'Submitted', 'Approved', 'Discarded', 'Archived'];
  searchCriteria = {onlyMyRevisions: true, status: '', part: null, operation: null};
  showResults = false;
  currentUser: User = null;
  revisions: RevisionSearchResult[];
  partCatalog: PartCatalogItem[] = [];
  operationsOfPart: OperationCatalogItem[] = [];
  allPartsCatalogItem: PartCatalogItem = {
    description: "All Parts",
    id: -1,
    operations: [],
  };
  allOperationsCatalogItem: OperationCatalogItem = {
    id: -1,
    description: "All Operations",
  };

  columnSize: ColumnSizeCSS = '0 1 calc(25% - 20px)';
  columnCount: number;

  selectedRevision: RevisionSearchResult = null;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    private messagingService: MessagingService,
    private sidePanelBusSingleton: SidePanelBus,
    private revisionsService: RevisionsService,
    private router: Router,
    private shellService: ShellService,
  ) {}

  get ownership() {
    return this.searchRevisionsForm.get('ownership');
  }

  get status() {
    return this.searchRevisionsForm.get('status');
  }

  get part() {
    return this.searchRevisionsForm.get('part');
  }

  get operation() {
    return this.searchRevisionsForm.get('operation');
  }

  ngOnInit() {

    this.revisionsService.getPartCatalog().subscribe(parts => {
      this.partCatalog = parts;
      this.partCatalog.unshift(this.allPartsCatalogItem)
    });

    this.initForm();

    this.shellService.currentUser.subscribe(user => {
      this.currentUser = user;
      this.initForm();
    });

    this.breakpointObserver.observe(AARTMediaQueries)
      .subscribe(state => {
        this.columnCount = CountColumns(state);
        this.columnSize = CalculateColumnSize(state);
      });
  }

  searchRevisions() {
    this.searchCriteria = {
      onlyMyRevisions: this.ownership.value === 'Only My Revisions',
      status: this.status.value === 'All Status' ? null : this.status.value.toUpperCase().replace(/ /g, "_"),
      part: this.part.value.id === -1 ? null : this.part.value.id,
      operation: this.operation.value.id === -1 ? null : this.operation.value.id,
    };

    this.revisionsService.searchRevisions(this.searchCriteria)
      .subscribe(
        revisions => {
          this.revisions = revisions;
          this.showResults = true;
        },
        () => {
          this.messagingService.error('There was a problem searching revisions. Please try again.');
          this.showResults = false;
        },
      );
  }

  editSearchCriteria() {
    if (this.columnCount < 2) {
      this.showResults = false;
    }
  }

  initForm() {
    this.searchRevisionsForm = this.fb.group({
      ownership: [this.currentUser === null
                  ? '' : this.currentUser.privileges.includes('REVIEWER')
                         ? 'All Revisions'
                         : 'Only My Revisions'],
      status: [this.currentUser === null
               ? '' : this.currentUser.privileges.includes('REVIEWER')
                      ? 'Submitted'
                      : 'In Progress'],
      part: new FormControl({value: this.allPartsCatalogItem, disabled: false}),
      operation: new FormControl({value: this.allOperationsCatalogItem, disabled: true}),
    });
  }

  openRevision(revision: RevisionSearchResult) {
    this.selectedRevision = revision;
    this.sidePanelBusSingleton.clear();
    this.sidePanelBusSingleton.revisionId = revision.id;
    this.sidePanelBusSingleton.operation = revision.operation;
    this.sidePanelBusSingleton.part = revision.part;
    this.router.navigate(['search', {outlets: {side: 'revision'}}]).catch();
  }

  partChanged(value: PartCatalogItem) {
    if (value === this.allPartsCatalogItem) {
      this.operation.setValue(this.allOperationsCatalogItem);
      this.operation.disable();
      this.operationsOfPart = [];
    } else {
      this.operation.enable();
      this.operationsOfPart = [this.allOperationsCatalogItem, ...this.part.value.operations];
    }
  }
}
