import { Pipe, PipeTransform } from "@angular/core";
import { fromUnixTime, format } from 'date-fns';

@Pipe({
  name: 'date',
  pure: true,
})
export class DatePipe implements PipeTransform {
  transform(epoch: number) {
    return format(new Date(fromUnixTime(epoch)), 'P');
  }
}
