import { BreakpointState } from "@angular/cdk/layout";

export type AARTQuery =
  '(max-width: 750px)' |
  '(min-width: 751px) and (max-width: 1200px)' |
  '(min-width: 1201px) and (max-width: 1600px)' |
  '(min-width: 1601px)';

export type ColumnSizeCSS =
  '100%' |
  '0 1 calc(50% - 20px)' |
  '0 1 calc(33.3% -20px)' |
  '0 1 calc(25% - 20px)';

export const AARTMediaQueries: AARTQuery[] = [
  '(max-width: 750px)',
  '(min-width: 751px) and (max-width: 1200px)',
  '(min-width: 1201px) and (max-width: 1600px)',
  '(min-width: 1601px)',
];

export const ColumnSizes: ColumnSizeCSS[] = [
  '100%',
  '0 1 calc(50% - 20px)',
  '0 1 calc(33.3% -20px)',
  '0 1 calc(25% - 20px)',
];

export function CountColumns(state: BreakpointState): number {
  return Object.values(state.breakpoints).findIndex(i => !!i) + 1;
}

export function CalculateColumnSize(state): ColumnSizeCSS {
  return ColumnSizes[Object.values(state.breakpoints).findIndex(i => !!i)]
}
