<div *ngIf="which == 'LOGIN'" class="login-form">
  <div class="form-title">
    <h5>Log in</h5>
  </div>
  <mat-divider></mat-divider>
  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <mat-form-field appearance="fill" color="accent">
      <mat-label>Email</mat-label>
      <input autocomplete="username" formControlName="email" matInput required>
    </mat-form-field>
    <mat-error *ngIf="loginForm.dirty && loginForm.controls['email'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
    <mat-error
      *ngIf="loginForm.controls['email'].hasError('email') && !loginForm.controls['email'].hasError('required')"
      class="mat-error">
      Please enter a valid email address
    </mat-error>
    <mat-form-field appearance="fill" class="password-field" color="accent">
      <mat-label>Password</mat-label>
      <input autocomplete="current-password" formControlName="password" matInput placeholder="Password" required
             type="password">
    </mat-form-field>
    <div *ngIf="locked" class="warn-box">
      <mat-icon matPrefix>report_problem</mat-icon>
      Your account has been locked due to too many failed attempts. Wait a few seconds before trying again.
    </div>
    <div *ngIf="unauthorized != ''" class="warn-box">
      <mat-icon matPrefix>report_problem</mat-icon>
      {{unauthorized}}
    </div>
    <button [disabled]="loginForm.invalid" color="accent" mat-raised-button type="submit" class="margin-top-20">
      LOG IN
    </button>
    <button (click)="$event.preventDefault(); which = 'RESET'" mat-button class="margin-top-20">Reset password</button>
  </form>
</div>

<div *ngIf="which == 'RESET'" class="login-form">
  <div class="form-title">
    <h5>Reset Password</h5>
  </div>
  <mat-divider></mat-divider>
  <p>A link to reset your password will be sent to the email address we have for your account.</p>
  <form (ngSubmit)="reset()" *ngIf="resetState != 'ANSWERED'" [formGroup]="resetForm">
    <mat-form-field appearance="fill" color="accent">
      <mat-label>Email</mat-label>
      <input autocomplete="off" formControlName="email" matInput>
    </mat-form-field>
    <mat-error *ngIf="loginForm.dirty && loginForm.controls['email'].hasError('required')">
      Email is <strong>required</strong>
    </mat-error>
    <mat-error
      *ngIf="loginForm.controls['email'].hasError('email') && !loginForm.controls['email'].hasError('required')"
      class="mat-error">
      Please enter a valid email address
    </mat-error>
    <button [disabled]="resetForm.invalid || resetState == 'INFLIGHT'" color="accent" mat-raised-button class="margin-top-20"
            type="submit">
      <span *ngIf="resetState === 'EDITING'">Reset Password</span>
      <mat-spinner *ngIf="resetState === 'INFLIGHT'" [diameter]="25"></mat-spinner>
    </button>
  </form>
  <div *ngIf="resetState == 'ANSWERED'">
    Please check your email.
  </div>
</div>
