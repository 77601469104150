import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
export class ShellService {
    constructor() {
        this.userSource = new BehaviorSubject(null);
        this.currentUser = this.userSource.asObservable();
        this.refreshVehicleSource = new BehaviorSubject(null);
        this.refreshVehicle = this.refreshVehicleSource.asObservable();
    }
    setUser(user) {
        this.userSource.next(user);
    }
    setRefreshVehicle(refresh) {
        this.refreshVehicleSource.next(refresh);
    }
}
ShellService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShellService_Factory() { return new ShellService(); }, token: ShellService, providedIn: "root" });
