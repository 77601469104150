<body>
<div class="page-container">
  <mat-sidenav-container (backdropClick)="eventBus.bdClose()">
    <mat-sidenav-content>
      <mat-toolbar class="mat-elevation-z2" color="primary">
        <mat-toolbar-row>
          <img class="logo-image" src="../../../assets/images/logo.png">
          <span class="spacer"></span>
          <div class="toolbar-icons">
            <div [class.active]="searchVehicles" class="toolbar-button">
              <button (click)="search()" mat-icon-button>
                <mat-icon>search</mat-icon>
              </button>
            </div>
            <div *ngIf="currentUser?.privileges.includes('ADMIN')" [class.active]="editUsers" class="toolbar-button">
              <button (click)="searchUsers()" mat-icon-button>
                <mat-icon>group</mat-icon>
              </button>
            </div>
            <div *ngIf="currentUser?.privileges.includes('EXPERT') || currentUser?.privileges.includes('REVIEWER')"
                 [class.active]="searchRevisions"
                 class="toolbar-button">
              <button (click)="searchRevisionsClicked()" mat-icon-button>
                <mat-icon>all_inbox</mat-icon>
              </button>
            </div>
            <div class="toolbar-button">
              <button [matMenuTriggerFor]="userMenu" mat-icon-button>
                <mat-icon>account_circle</mat-icon>
              </button>
              <mat-menu #userMenu="matMenu">
                <div class="user-menu">
                  <div class="data-holder">
                    <div class="privilege">{{currentUser?.privileges?.join(", ")}}</div>
                    <h6 class="display-ellipsis">{{currentUser?.firstName}} {{currentUser?.lastName}}</h6>
                    <div class="display-force-wrap">{{currentUser?.email}}</div>
                  </div>
                  <div class="initials-wrapper">
                    <div class="user-initials">
                      {{currentUser?.firstName.charAt(0)}}{{currentUser?.lastName.charAt(0)}}
                    </div>
                  </div>
                </div>
                <button (click)="logout()" mat-menu-item>
                  <span>Log out</span>
                </button>
              </mat-menu>
            </div>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <router-outlet></router-outlet>
      <div class="footer-container">
        <a href="https://www.stelvio.com/" target="_blank">
          <div class="logo-white"></div>
        </a>
        <div class="footer-text white">All contents copyright 1990-{{currentYear}} Stelvio Inc.</div>
      </div>
    </mat-sidenav-content>
    <mat-sidenav (closed)="resetRouter()" [opened]="openSidebar" position="end">
      <router-outlet (activate)="openSidebar=true" (deactivate)="openSidebar=false" name="side"></router-outlet>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
</body>
