import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginCredentials } from '../model/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient) {}

  login = (creds: LoginCredentials) => this.http.post('sso/login', creds);

  logout = () => this.http.post('sso/logout', null);

  refresh = () => this.http.post('sso/refresh', null)
}
