import { Component, OnInit } from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Router} from "@angular/router";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  currentYear = new Date().getFullYear();

  isMobile: boolean = false;
  isTablet: boolean = false;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {}

  ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe(result => {
        this.isMobile = result.breakpoints[Breakpoints.HandsetPortrait];
        this.isTablet = result.breakpoints[Breakpoints.TabletPortrait] || result.breakpoints[Breakpoints.HandsetLandscape];
      })
  }

  login() {
    this.router.navigate(['welcome/', { outlets: { side: 'login'}}]).catch();
  }

  register() {
    this.router.navigate(['welcome/', { outlets: { side: 'register'}}]).catch();
  }

}
