<div>
  <div class="header">
    <div class="part-detail">
      {{partName}}
    </div>
    <h5>{{operationName}}</h5>
  </div>

  <div *ngIf="sidePanel && sidePanel.publishedTimeMinutes" class="top-card-container">
    <mat-expansion-panel [expanded]="topCardExpandedInitially()" class="mat-elevation-z0">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <div class="card-header">
          <div>
            <div class="title">
              Published Time
            </div>
          </div>
          <div>
            <div class="total-time">
              <h6>{{sidePanel.publishedTimeMinutes | duration:'hours'}}</h6>
              <div class="time-minutes">{{sidePanel.publishedTimeMinutes | duration:'hh:mm'}}</div>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>
      <ng-container *ngIf="sidePanel.impactedDescriptors?.length">
        <div class="title-border">
          <div class="title">Applies to vehicles similar to</div>
        </div>
        <ul>
          <li
            *ngFor="let d of (expandImpacted ? sidePanel.impactedDescriptors : sidePanel.impactedDescriptors.slice(0,5))">
            <app-descriptor [descriptor]="d"></app-descriptor>
          </li>
        </ul>
        <button (click)="expandImpacted = !expandImpacted" *ngIf="sidePanel.impactedDescriptors.length > 5"
                mat-button>
          {{ expandImpacted ? "Close" : "Show " + (sidePanel.impactedDescriptors?.length - 5) + " more" }}
        </button>
      </ng-container>
    </mat-expansion-panel>
  </div>
  <div *ngIf="sidePanel?.topRevision" class="top-card-container">
    <mat-expansion-panel [expanded]="topCardExpand" class="mat-elevation-z0">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">

        <div class="card-header">
          <div>
            <div class="title">
              {{sidePanel.topRevision.status === 'DISCARDED' ? 'No Published Time' : 'Published Time'}}
            </div>
            <div *ngIf="sidePanel.topRevision.status !== 'DISCARDED'" class="date">
              {{sidePanel.topRevision.publishedDate | date}}
            </div>
          </div>
          <div>
            <div *ngIf="sidePanel.topRevision.status !== 'DISCARDED'" class="total-time">
              <h6>{{sidePanel.topRevision.publishedRepairTimeMinutes | duration:'hours'}}</h6>
              <div class="time-minutes">{{sidePanel.topRevision.publishedRepairTimeMinutes | duration:'hh:mm'}}</div>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div [ngClass]="sidePanel.topRevision.status" class="revision-header">
        <div class="font-14">
          REVISION
        </div>
        <div class="info-chip">
          <mat-icon matPrefix>{{sidePanel.topRevision.status | revisionStatusIcon}}</mat-icon>
          <span class="font-12">{{sidePanel.topRevision.status | revisionStatus}}</span>
        </div>
        <div>
          <button (click)="toggleHistory('TOP')" mat-icon-button>
            <mat-icon>{{showHistory ? 'clear' : 'history'}}</mat-icon>
          </button>
        </div>

        <div *ngIf="showHistory" class="history">
          <div class="section-title">
            HISTORY
          </div>
          <div *ngFor="let element of historyElements">
            <div class="list-element">
              {{element.action}} by {{element.firstName}} {{element.lastName}} on<br/>
              {{element.timestamp | date}} at {{element.timestamp | time}}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="sidePanel.topRevision.steps.length" class="steps-panel">
        <div class="details">Details</div>
        <div *ngIf="sidePanel.topRevision.steps.length > 1 || sidePanel.topRevision.steps[0].stepNumber">
          <div *ngFor="let step of sidePanel.topRevision.steps">
            <mat-expansion-panel #panel
                                 [disabled]="!step.description && !step.containsImage" class="mat-elevation-z0"
                                 hideToggle="true">
              <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                <div class="steps">
                  <div class="step-title font-14">
                    {{step.stepNumber}}. {{step.title}}
                  </div>
                  <div [class.selected]="panel.expanded" class="step-icons">
                    <mat-icon *ngIf="step.description">textsms</mat-icon>
                    <mat-icon *ngIf="step.containsImage">filter</mat-icon>
                  </div>
                  <div class="step-time">
                    <div>
                      {{step.repairTimeMinutes ? (step.repairTimeMinutes | duration:'hours') : ''}}
                    </div>
                    <div class="time-pale font-12">
                      {{step.repairTimeMinutes ? (step.repairTimeMinutes | duration:'hh:mm') : ''}}
                    </div>
                  </div>
                </div>
              </mat-expansion-panel-header>
              <div (click)="openLightbox(sidePanel.topRevision.id, step.stepNumber)" *ngIf="step.containsImage"
                   class="picture">
                <img src="/servlet/image?revisionId={{sidePanel.topRevision.id}}&stepNumber={{step.stepNumber}}">
              </div>
              <div *ngIf="step.description" class="description">
                {{step.description}}
              </div>
            </mat-expansion-panel>
          </div>
        </div>

        <div class="steps">
          <div class="step-title font-14 black-emphasis">
            Total Time
          </div>
          <div></div>
          <div class="step-time">
            <div>
              {{totalTopRevisionTime | duration:'hours'}}
            </div>
            <div class="time-pale font-12">
              {{totalTopRevisionTime | duration:'hh:mm'}}
            </div>
          </div>
        </div>
        <div
          *ngIf="sidePanel.topRevision && sidePanel.topRevision?.contingencyUnit"
          class="steps">
          <div class="step-title font-14">Contingency</div>
          <div></div>
          <div class="step-time">
            {{sidePanel.topRevision.contingencyUnit === 'PERCENTAGE' ? '+' + sidePanel.topRevision.contingency + '%' : '+' + (sidePanel.topRevision.contingency | duration:"hh:mm")}}
          </div>
        </div>
      </div>

      <ng-container *ngIf="sidePanel.topRevision.impactedDescriptors?.length">
        <div class="title-border">
          <div class="title">Applies to vehicles similar to</div>
        </div>
        <ul>
          <li
            *ngFor="let d of (expandImpacted ? sidePanel.topRevision.impactedDescriptors : sidePanel.topRevision.impactedDescriptors.slice(0,5))">
            <app-descriptor [descriptor]="d"></app-descriptor>
          </li>
        </ul>
        <button (click)="expandImpacted = !expandImpacted" *ngIf="sidePanel.topRevision.impactedDescriptors.length > 5"
                mat-button>
          {{ expandImpacted ? "Close" : "Show " + (sidePanel.topRevision.impactedDescriptors?.length - 5) + " more" }}
        </button>
      </ng-container>

    </mat-expansion-panel>
  </div>

</div>
<ng-container *ngIf="sidePanel?.archivedRevisionCount > 0">

  <div *ngFor="let archivedRevision of archivedRevisions">

    <div *ngIf="archivedRevision" class="top-card-container">

      <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">

          <div class="card-header">
            <div>
              <div class="title">
                {{archivedRevision.status === 'DISCARDED' ? 'No Published Time' : 'Published Time'}}
              </div>
              <div *ngIf="archivedRevision.status !== 'DISCARDED'" class="date">
                {{archivedRevision.publishedDate | date}}
              </div>
            </div>
            <div>
              <div *ngIf="archivedRevision.status !== 'DISCARDED'" class="total-time">
                <h6>{{archivedRevision.publishedRepairTimeMinutes | duration:'hours'}}</h6>
                <div class="time-minutes">{{archivedRevision.publishedRepairTimeMinutes | duration:'hh:mm'}}</div>
              </div>
            </div>
          </div>
        </mat-expansion-panel-header>
        <div [ngClass]="archivedRevision.status" class="revision-header">
          <div class="font-14">
            REVISION
          </div>
          <div class="info-chip">
            <mat-icon matPrefix>{{archivedRevision.status | revisionStatusIcon}}</mat-icon>
            <span class="font-12">{{archivedRevision.status | revisionStatus}}</span>
          </div>
          <div>
            <button (click)="toggleHistory('TOP')" mat-icon-button>
              <mat-icon>{{showHistory ? 'clear' : 'history'}}</mat-icon>
            </button>
          </div>

          <div *ngIf="showHistory" class="history">
            <div class="section-title">
              HISTORY
            </div>
            <div *ngFor="let element of historyElements">
              <div class="list-element">
                {{element.action}} by {{element.firstName}} {{element.lastName}} on<br/>
                {{element.timestamp | date}} at {{element.timestamp | time}}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="archivedRevision.steps.length" class="steps-panel">
          <div class="details">Details</div>
          <div *ngIf="archivedRevision.steps.length > 1 || archivedRevision.steps[0].stepNumber">
            <div *ngFor="let step of archivedRevision.steps">
              <mat-expansion-panel #panel
                                   [disabled]="!step.description && !step.containsImage" class="mat-elevation-z0"
                                   hideToggle="true">
                <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
                  <div class="steps">
                    <div class="step-title font-14">
                      {{step.stepNumber}}. {{step.title}}
                    </div>
                    <div [class.selected]="panel.expanded" class="step-icons">
                      <mat-icon *ngIf="step.description">textsms</mat-icon>
                      <mat-icon *ngIf="step.containsImage">filter</mat-icon>
                    </div>
                    <div class="step-time">
                      <div>
                        {{step.repairTimeMinutes ? (step.repairTimeMinutes | duration:'hours') : ''}}
                      </div>
                      <div class="time-pale font-12">
                        {{step.repairTimeMinutes ? (step.repairTimeMinutes | duration:'hh:mm') : ''}}
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div (click)="openLightbox(archivedRevision.id, step.stepNumber)" *ngIf="step.containsImage"
                     class="picture">
                  <img src="/servlet/image?revisionId={{archivedRevision.id}}&stepNumber={{step.stepNumber}}">
                </div>
                <div *ngIf="step.description" class="description">
                  {{step.description}}
                </div>
              </mat-expansion-panel>
            </div>
          </div>

          <div class="steps">
            <div class="step-title font-14 black-emphasis">
              Total Time
            </div>
            <div></div>
            <div class="step-time">
              <div>
                {{totalTopRevisionTime | duration:'hours'}}
              </div>
              <div class="time-pale font-12">
                {{totalTopRevisionTime | duration:'hh:mm'}}
              </div>
            </div>
          </div>
          <div
            *ngIf="archivedRevision && archivedRevision?.contingencyUnit"
            class="steps">
            <div class="step-title font-14">Contingency</div>
            <div></div>
            <div class="step-time">
              {{archivedRevision.contingencyUnit === 'PERCENTAGE' ? '+' + archivedRevision.contingency + '%' : '+' + (archivedRevision.contingency | duration:"hh:mm")}}
            </div>
          </div>
        </div>

        <ng-container *ngIf="archivedRevision.impactedDescriptors?.length">
          <div class="title-border">
            <div class="title">Applies to vehicles similar to</div>
          </div>
          <ul>
            <li
              *ngFor="let d of (expandImpacted ? archivedRevision.impactedDescriptors : archivedRevision.impactedDescriptors.slice(0,5))">
              <app-descriptor [descriptor]="d"></app-descriptor>
            </li>
          </ul>
          <button (click)="expandImpacted = !expandImpacted" *ngIf="archivedRevision.impactedDescriptors.length > 5"
                  mat-button>
            {{ expandImpacted ? "Close" : "Show " + (archivedRevision.impactedDescriptors?.length - 5) + " more" }}
          </button>
        </ng-container>

      </mat-expansion-panel>
    </div>

  </div>
  <div (click)="getArchivedRevisions()" *ngIf="archivedRevisions.length === 0" class="archived-button">
    <button mat-button>
      SHOW ARCHIVED REVISIONS
    </button>
  </div>

  <div (click)="hideArchivedRevisions()" *ngIf="archivedRevisions.length > 0" class="archived-button">
    <button mat-button>
      HIDE ARCHIVED REVISIONS
    </button>
  </div>
</ng-container>

<div *ngIf="sidePanel?.bottomRevision" class="bottom">

  <header [ngClass]="sidePanel?.bottomRevision.status" class="revision-header">
    <div>
      <div class="font-14">
        NEW REVISION
      </div>
    </div>
    <div class="info-chip">
      <mat-icon matPrefix>{{sidePanel?.bottomRevision.status | revisionStatusIcon}}</mat-icon>
      <span class="font-12">{{sidePanel?.bottomRevision.status | revisionStatus}}</span>
    </div>
    <div>
      <button (click)="toggleHistory('BOTTOM')" mat-icon-button>
        <mat-icon>{{showHistory ? 'clear' : 'history'}}</mat-icon>
      </button>
    </div>

    <div *ngIf="showHistory" class="history">
      <div class="section-title">
        HISTORY
      </div>
      <div *ngFor="let element of historyElements">
        <div class="list-element">
          {{element.action}} by {{element.firstName}} {{element.lastName}} on<br/>
          {{element.timestamp | date}} at {{element.timestamp | time}}
        </div>
      </div>
    </div>
  </header>

  <div
    *ngIf="sidePanel?.bottomRevision?.status === 'IN_PROGRESS' && currentUser?.id !== sidePanel.bottomRevision?.expertId && currentUser?.privileges.includes('EXPERT')"
    class="redundancy-container">
    <div *ngIf="sidePanel?.bottomRevision.reserved" class="actions">
      <mat-card class="explanation-container mat-elevation-z1">
        <mat-icon color="accent" matPrefix>error_outline</mat-icon>
        <div class="explanation">
          Reserved until
          {{sidePanel?.bottomRevision.reservedUntilDate | date}}.
        </div>
      </mat-card>
    </div>
    <div *ngIf="!sidePanel?.bottomRevision.reserved" class="actions">
      <mat-card class="explanation-container mat-elevation-z1 expired">
        <mat-icon color="warn" matPrefix>error_outline</mat-icon>
        <div class="explanation">
          Previously reserved, now available to everyone.
        </div>
      </mat-card>
    </div>
    <div *ngIf="showStartRevision" class="search-button">
      <button (click)="editRevision()" color="accent" mat-raised-button>
        <mat-icon>rate_review_2</mat-icon>
        START REVISION
      </button>
    </div>
  </div>

  <div *ngIf="(sidePanel?.bottomRevision?.status !== 'IN_PROGRESS' && (isExpertOwner() || currentUser?.privileges.includes('REVIEWER'))) ||
  (sidePanel?.bottomRevision?.status === 'IN_PROGRESS' && isExpertOwner())" class="redundancy-container">

    <mat-card *ngIf="sidePanel.bottomRevision?.rejectionNote" class="rejection mat-elevation-z1">
      <div>
        <mat-icon color="accent">report</mat-icon>
        <div class="orange">Rejected</div>
      </div>
      <div>{{sidePanel.bottomRevision.rejectionNote}}</div>
    </mat-card>

    <div *ngIf="sidePanel?.bottomRevision?.steps.length" class="steps-panel">
      <div class="details">Details</div>
      <div *ngIf="sidePanel?.bottomRevision?.steps.length > 1 || sidePanel?.bottomRevision?.steps[0].stepNumber">
        <div *ngFor="let step of sidePanel?.bottomRevision?.steps">
          <mat-expansion-panel #panel
                               [disabled]="!step.description && !step.containsImage" class="mat-elevation-z0"
                               hideToggle="true">
            <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
              <div class="steps">
                <div class="step-title font-14">
                  {{step.stepNumber}}. {{step.title}}
                </div>
                <div [class.selected]="panel.expanded" class="step-icons">
                  <mat-icon *ngIf="step.description">textsms</mat-icon>
                  <mat-icon *ngIf="step.containsImage">filter</mat-icon>
                </div>
                <div class="step-time">
                  <div>
                    {{step.repairTimeMinutes ? (step.repairTimeMinutes | duration:'hours') : ''}}
                  </div>
                  <div class="time-pale font-12">
                    {{step.repairTimeMinutes ? (step.repairTimeMinutes | duration:'hh:mm') : ''}}
                  </div>
                </div>
              </div>
            </mat-expansion-panel-header>
            <div (click)="openLightbox(sidePanel?.bottomRevision.id, step.stepNumber)" *ngIf="step.containsImage"
                 class="picture">
              <img src="/servlet/image?revisionId={{sidePanel?.bottomRevision.id}}&stepNumber={{step.stepNumber}}">
            </div>
            <div *ngIf="step.description" class="description">
              {{step.description}}
            </div>
          </mat-expansion-panel>
        </div>
      </div>

      <div class="steps">
        <div class="step-title font-14">
          Total Time
        </div>
        <div></div>
        <div class="step-time">
          <div>
            {{revisedTime | duration:'hours'}}
          </div>
          <div class="time-pale font-12">
            {{revisedTime | duration:'hh:mm'}}
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="impactedDescriptors?.length">
      <div class="title-border">
        <div class="title">Applies to vehicles similar to</div>
        <div class="title-icon">
          <button (click)="selectImpactedDescriptors()"
                  *ngIf="currentUser?.privileges.includes('REVIEWER') && sidePanel?.bottomRevision?.status === 'SUBMITTED'"
                  mat-icon-button>
            <mat-icon>edit</mat-icon>
          </button>
        </div>
      </div>
      <ul>
        <li *ngFor="let d of (expandImpacted ? impactedDescriptors : impactedDescriptors.slice(0,5))">
          <app-descriptor [descriptor]="d"></app-descriptor>
        </li>
      </ul>
      <button (click)="expandImpacted = !expandImpacted" *ngIf="impactedDescriptors.length > 5"
              mat-button>
        {{ expandImpacted ? "Close" : "Show " + (impactedDescriptors?.length - 5) + " more" }}
      </button>
    </ng-container>

    <div *ngIf="currentUser?.privileges.includes('EXPERT') || currentUser?.privileges.includes('REVIEWER')"
         class="actions">
      <h5 *ngIf="sidePanel?.bottomRevision?.status === 'IN_PROGRESS' && currentUser?.privileges.includes('EXPERT')"
          class="actions-title">
        Actions
      </h5>
      <h5 *ngIf="sidePanel.bottomRevision.status === 'SUBMITTED' && currentUser?.privileges.includes('REVIEWER')"
          class="actions-title">
        Revision
      </h5>
      <div *ngIf="showSubmitRevision && (!(revisedTime > 0) || hasEmptyStep)">
        <mat-card class="explanation-container mat-elevation-z1">
          <mat-icon matPrefix>error_outline</mat-icon>
          <div class="explanation">
            You can submit once there are no empty steps and the Revised Time is greater than 0.
          </div>
        </mat-card>
      </div>

      <div *ngIf="sidePanel?.bottomRevision.reserved">
        <mat-card class="explanation-container mat-elevation-z1">
          <mat-icon color="accent" matPrefix>error_outline</mat-icon>
          <div class="explanation">
            Reserved <span *ngIf="currentUser.id === sidePanel.bottomRevision.expertId">for you</span> until
            {{sidePanel?.bottomRevision.reservedUntilDate | date}}.
          </div>
        </mat-card>
      </div>

      <div *ngIf="sidePanel?.bottomRevision?.status === 'IN_PROGRESS' && !sidePanel?.bottomRevision.reserved">
        <mat-card class="explanation-container mat-elevation-z1 expired">
          <mat-icon color="warn" matPrefix>error_outline</mat-icon>
          <div class="explanation">
            Available to everyone. Please submit as soon as possible.
          </div>
        </mat-card>
      </div>

      <ng-container *ngIf="isExpert() && isExpertOwner() && sidePanel.bottomRevision.status === 'IN_PROGRESS'">
        <div class="search-button">
          <button (click)="editRevision()" mat-button>CONTINUE EDITING</button>
        </div>

        <button (click)="submitRevision()" *ngIf="showSubmitRevision"
                [disabled]="!(revisedTime > 0) || hasEmptyStep" color="accent"
                mat-raised-button>
          <mat-icon>check_circle</mat-icon>
          SUBMIT
        </button>

        <div class="search-button red">
          <button (click)="discardRevision()" mat-button>DISCARD</button>
        </div>
      </ng-container>

      <div *ngIf="showStartRevision" class="search-button">
        <button (click)="editRevision()" color="accent" mat-raised-button>
          <mat-icon>rate_review_2</mat-icon>
          START REVISION
        </button>
      </div>

      <form *ngIf="currentUser?.privileges.includes('REVIEWER') && sidePanel.bottomRevision.status === 'SUBMITTED'"
            [formGroup]="approveRejectForm">
        <div class="radio-button-container">
          <mat-checkbox (change)="approveRadioButtonChecked($event)" formControlName="approve">
            Approve
          </mat-checkbox>
          <div class="second-radio-button">
            <mat-checkbox (change)="rejectRadioButtonChecked($event)" class="second-radio-button"
                          formControlName="reject">
              Reject
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="formType !== 'BLANK'" class="approval-section">
          <p *ngIf="formType === 'Approve'">
            The proposal will be published and available to everyone.
          </p>
          <p *ngIf="formType === 'Reject'">
            The proposal will be sent back to its creator for changes.
          </p>
          <div *ngIf="formType === 'Approve'" class="contingency-holder">
            <mat-form-field appearance="fill" class="contingency" color="accent">
              <mat-label>Contingency</mat-label>
              <input formControlName="contingency" matInput min="0" step="1" type="number"/>
              <mat-error *ngIf="contingency.touched && contingency.hasError('required')">
                Contingency is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="contingency.touched && contingency.hasError('pattern')">
                Contingency must be a whole number
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" class="contingency-unit" color="accent">
              <mat-label>Unit</mat-label>
              <mat-select formControlName="contingencyUnit">
                <mat-option [value]="'MINUTES'">minutes</mat-option>
                <mat-option [value]="'PERCENTAGE'">%</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="formType === 'Approve'" class="total">
          <div class="title">Revised Time</div>
          <div class="total-time">
            <h6>{{newPublishedTime | duration:'hours'}}</h6>
            <div class="time-minutes">{{newPublishedTime | duration:"hh:mm"}}</div>
          </div>
        </div>
        <mat-form-field *ngIf="formType === 'Reject'" appearance="fill" class="note" color="accent">
          <mat-label>Note</mat-label>
          <textarea [cdkAutosizeMinRows]="1" cdkTextareaAutosize
                    formControlName="note"
                    matInput></textarea>
          <mat-error *ngIf="note.touched && note.hasError('required')">
            Note is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-card *ngIf="formType !== 'BLANK'" class="approve-button mat-elevation-z4">
          <p *ngIf="formType === 'Approve'">
            To approve the revision, please enter your password and click APPROVE.
          </p>
          <p *ngIf="formType === 'Reject'">
            To reject the revision, please enter your password and click REJECT.
          </p>
          <mat-form-field appearance="fill" class="password" color="accent">
            <mat-label>Password</mat-label>
            <input autocomplete="current-password" formControlName="password" matInput type="password"/>
          </mat-form-field>
          <mat-error *ngIf="password.touched && password.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
          <div *ngIf="unauthorized != ''" class="warn-box">
            <mat-icon matPrefix>report_problem</mat-icon>
            {{unauthorized}}
          </div>
          <button (click)="approveOrRejectButtonClicked()"
                  [disabled]="approveOrRejectButtonDisabled()"
                  class="search-button" color="accent"
                  mat-raised-button>
            <span *ngIf="!spinning">{{this.formType}}</span>
            <mat-spinner *ngIf="spinning" [diameter]="25"></mat-spinner>
          </button>
        </mat-card>
      </form>
    </div>
  </div>
</div>

<button (click)="editRevision()"
        *ngIf="currentUser?.privileges.includes('EXPERT') && showStartRevision && !sidePanel.hasOwnProperty('bottomRevision')"
        class="no-revision-button" color="accent"
        mat-raised-button>
  <mat-icon>rate_review_2</mat-icon>
  START REVISION
</button>
