import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginCredentials, SetPasswordCredentials, User } from '../model/user.model';
import { AARTParameterCodec } from '../helpers/service.helper';
import { PrivilegeUpdate } from '../model/privilege-update.model';
import { Email } from "../types/email.type";

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {
  }

  private static handleError(error: any) {
    return Promise.reject(error.error || error.message || error);
  }

  static sortUsers = (a, b) => (
    a.lastName.localeCompare(b.lastName)
  )

  register(user: User) {
    user.email = user.email.toLowerCase();

    return this.http.post(`api/unsecured/register`, user)
      .pipe(
        catchError(UserService.handleError),
      );
  }

  resetPassword(email: Email) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: undefined,
    };

    const encoder = new AARTParameterCodec();
    options.params = new HttpParams({encoder}).set("email", email);

    return this.http.get(`api/unsecured/resetPassword`, options)
      .pipe(
        catchError(UserService.handleError),
      );
  }

  setPassword = (creds: SetPasswordCredentials) => this.http.post('sso/set', creds);

  login = (creds: LoginCredentials) => this.http.post('sso/login', creds);

  getCurrentUser() {
    return this.http.get<User>('api/administration/user')
      .pipe(
        catchError(UserService.handleError),
      );
  }

  searchUsers(searchCriteria) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: undefined,
    };

    const encoder = new AARTParameterCodec();
    options.params = new HttpParams({encoder});

    if (searchCriteria.user) {
      options.params = options.params.append('query', searchCriteria.user);
    }

    if (searchCriteria.privilege) {
      options.params = options.params.append('privilege', searchCriteria.privilege);
    }

    return this.http.get<User[]>('api/administration/users', options)
      .pipe(
        tap(tcm => tcm.sort(UserService.sortUsers)),
        catchError(UserService.handleError),
      );
  }

  updateUser(privilegeUpdate: PrivilegeUpdate) {
    return this.http.post('api/administration/updatePrivileges', privilegeUpdate)
      .pipe(
        catchError(UserService.handleError),
      );
  }
}

