import {AARTParameterCodec} from "../../helpers/service.helper";
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RevisionSearchResult} from "../../model/revisionSearchResult.model";
import { PartCatalogItem } from "../../model/partCatalog.model";

@Injectable()
export class RevisionsService {

  private _options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

  constructor(private _http: HttpClient) {
    this._options['responseType'] = 'json';
  }

  private static handleError(error: any) {
    return Promise.reject(error.error || error.message || error);
  }

  static sortRevisions =
    (a: RevisionSearchResult, b: RevisionSearchResult) =>
      (a.lastTransactionDateTime - b.lastTransactionDateTime);

  searchRevisions(searchCriteria) {
    const options = {...this._options};
    const encoder = new AARTParameterCodec();
    options['params'] = new HttpParams({encoder});

    options['params'] = options['params'].append('onlyMyRevisions', searchCriteria.onlyMyRevisions);

    if (searchCriteria.status) {
      options['params'] = options['params'].append('status', searchCriteria.status);
    }

    if (searchCriteria.part) {
      options['params'] = options['params'].append('partId', searchCriteria.part);
    }

    if (searchCriteria.operation) {
      options['params'] = options['params'].append('operationId', searchCriteria.operation);
    }

    return this._http.get<RevisionSearchResult[]>('api/revision/search', options)
      .pipe(
        catchError(RevisionsService.handleError)
      );
  }

  getPartCatalog() {
    return this._http.get<PartCatalogItem[]>('api/partCatalog')
      .pipe(
        catchError(RevisionsService.handleError)
      )
  }
}
