import { Component, OnInit } from '@angular/core';
import { SidePanelBus } from "./side-panel-bus.service";
import { UserUpdateService } from "../users/user-update.service";
import { User } from "../../model/user.model";
import { MatDialog } from "@angular/material/dialog";
import { EditRevisionComponent } from "./edit-revision.component";
import { MessagingService } from "../../components/messaging/messaging.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApproveRejectRevision } from "../../model/ApproveRejectRevision.model";
import { RevisionService } from "./revision.service";
import { ConfirmationComponent } from "../../components/confirmation/confirmation.component";
import { RevisionHistory } from "../../model/revision-history.model";
import { LightboxService } from "../../lightbox/lightbox.service";
import { DescriptorCore } from "../../model/descriptor.model";
import { SelectImpactedDescriptorsComponent } from "../select-impacted-descriptors/select-impacted-descriptors.component";
import { RevisionTop, SidePanel } from "../../model/coreRevision.model";
import { Step } from "../../model/revision.model";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Router } from '@angular/router';
import { ShellService } from "../shell/shell.service";
import { EventBusService } from "../../helpers/event-bus/event-bus.service";

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss'],
  providers: [RevisionService],
})
export class RevisionComponent implements OnInit {
  sidePanel: SidePanel;
  partName = '';
  operationName = '';
  repairTime = 0;
  currentUser: User = null;
  formType: "Approve" | "Reject" | "BLANK" = "BLANK";
  showStartRevision = false;
  showEditRevision = false;
  showSubmitRevision = false;
  hasEmptyStep = false;

  revisedTime = 0;
  unauthorized = '';
  spinning = false;
  showHistory = false;
  historyElements: RevisionHistory[] = null;
  impactedDescriptors: DescriptorCore[];
  totalTopRevisionTime = 0;

  approveRejectForm: FormGroup;
  expandImpacted = false;
  cacheKey: string;

  archivedRevisions: RevisionTop[] = [];

  topCardExpand = true;

  constructor(
    private fb: FormBuilder,
    private lightboxService: LightboxService,
    private messagingService: MessagingService,
    public sidePanelDataService: SidePanelBus,
    private revisionService: RevisionService,
    private userUpdateService: UserUpdateService,
    public matDialog: MatDialog,
    private router: Router,
    private shellService: ShellService,
    private eventBus: EventBusService,
  ) { }

  get approve() {
    return this.approveRejectForm.get('approve');
  }

  get reject() {
    return this.approveRejectForm.get('reject');
  }

  get contingency() {
    return this.approveRejectForm.get('contingency');
  }

  get contingencyUnit() {
    return this.approveRejectForm.get('contingencyUnit')
  }

  get newPublishedTime() {
    return this.revisedTime
           + Math.round(this.contingencyUnit.value === 'PERCENTAGE' ?
                        this.revisedTime * Math.abs(this.contingency.value) / 100.0 :
                        this.contingency.value);
  }

  get note() {
    return this.approveRejectForm.get('note');
  }

  get password() {
    return this.approveRejectForm.get('password');
  }

  static cacheForm = (cacheKey: string, form: ApproveRejectFormData) => {
    delete form.password; // Never cache the password
    sessionStorage.setItem(cacheKey, JSON.stringify(form));
  };

  loadCachedForm = (cacheKey: string) => {
    const cacheRaw = sessionStorage.getItem(cacheKey);
    if (cacheRaw) {
      const cacheData: ApproveRejectFormData = JSON.parse(cacheRaw);
      this.approveRejectForm.patchValue(cacheData);

      if (cacheData.approve || cacheData.reject) {
        this.formType = cacheData.approve ? "Approve" : "Reject";
        cacheData.approve
        ? this.approveRadioButtonChecked({checked: true} as MatCheckboxChange)
        : this.rejectRadioButtonChecked({checked: true} as MatCheckboxChange);
      } else {
        this.formType = "BLANK";
      }

      this.approveRejectForm.updateValueAndValidity();
    }
  };

  ngOnInit() {
    this.partName = this.sidePanelDataService.part;
    this.operationName = this.sidePanelDataService.operation;
    this.currentUser = this.userUpdateService.currentUser;

    this.approveRejectForm = this.fb.group({
      approve: false,
      reject: false,
      note: null,
      contingency: null,
      contingencyUnit: 'PERCENTAGE',
      password: ['', [Validators.required]],
    });

    if (this.sidePanelDataService.revisionId != null) {
      this.revisionService.getRevision(this.sidePanelDataService.revisionId)
        .subscribe(
          sidePanel => this.initSidePanel(sidePanel),
          () => this.messagingService.error('There was a problem loading the revision. Please try again.'),
          () => {
            this.cacheKey = `revision:${this.sidePanelDataService.revisionId}`;
            this.loadCachedForm(this.cacheKey);
          },
        );
    } else if (
      this.sidePanelDataService.descriptorId !== null &&
      this.sidePanelDataService.templateRepairTimeId !=
      null
    ) {
      this.revisionService.getLatestRevision(this.sidePanelDataService.descriptorId, this.sidePanelDataService.templateRepairTimeId)
        .subscribe(
          sidePanel => this.initSidePanel(sidePanel),
          () => this.messagingService.error('There was a problem loading the revision. Please try again.'),
          () => {
            this.cacheKey = `operation:${this.sidePanelDataService.operationId}`;
            this.loadCachedForm(this.cacheKey);
          },
        );
    }
  }

  initSidePanel(sidePanel: SidePanel) {
    this.sidePanel = sidePanel;

    this.showStartRevision = this.isExpert() && (!sidePanel.hasOwnProperty('topRevision') &&
                              !sidePanel.hasOwnProperty('bottomRevision')) ||
                             (sidePanel.topRevision && sidePanel.topRevision.canCreateNewRevision) ||
                             (sidePanel.bottomRevision && this.currentUser.id !== sidePanel.bottomRevision.expertId &&
                              sidePanel.bottomRevision.status === 'IN_PROGRESS' &&
                              !sidePanel.bottomRevision.reserved);

    if (sidePanel.hasOwnProperty('topRevision')) {
      this.totalTopRevisionTime = sidePanel.topRevision.steps.reduce((acc, step) => step.repairTimeMinutes
                                                                                    ? acc + step.repairTimeMinutes
                                                                                    : acc, 0);
    }

    // TODO Probably iterate this differently
    // this.repairTime = sidePanel.topRevision.publishedRepairTimeMinutes;

    if (sidePanel.hasOwnProperty('bottomRevision')) {
      this.revisedTime = sidePanel.bottomRevision.steps.reduce((acc, step) => step.repairTimeMinutes
                                                                              ? acc + step.repairTimeMinutes
                                                                              : acc, 0);

      this.showEditRevision = this.showSubmitRevision = this.currentUser.id === sidePanel.bottomRevision.expertId &&
                                                        sidePanel.bottomRevision.status === 'IN_PROGRESS';

      this.hasEmptyStep = sidePanel.bottomRevision.steps.filter((step: Step) =>
        (!step.title || step.title.length === 0) &&
        !step.repairTimeMinutes &&
        !step.description &&
        !step.containsImage).length > 0;

      this.topCardExpand = false;

      this.impactedDescriptors = this.sidePanel.bottomRevision.impactedDescriptors;
    }

    this.approveRejectForm.valueChanges
      .subscribe(form => RevisionComponent.cacheForm(this.cacheKey, form))

  }

  getArchivedRevisions() {

    this.revisionService.getArchivedRevisions(this.sidePanelDataService.revisionId != null
                                              ? this.sidePanel.topRevision.originalDescriptorId
                                              : this.sidePanelDataService.descriptorId, this.sidePanel.topRevision.operationId)
      .subscribe(
        archivedRevisions => this.archivedRevisions = archivedRevisions,
        () => this.messagingService.error('There was a problem loading the archived revisions. Please try again.'),
      );
  }

  hideArchivedRevisions() {
    this.archivedRevisions = [];
  }

  selectImpactedDescriptors() {
    const dialogRef = this.matDialog.open(SelectImpactedDescriptorsComponent, {
      maxWidth: '90vw',
      minWidth: '280px',
      width: '748px',
      maxHeight: '100vh',
      autoFocus: false,
      disableClose: true,
      data: {
        operationId: this.sidePanel.bottomRevision.operationId,
        operationName: this.sidePanel.bottomRevision.operation,
        originalDescriptorId: this.sidePanel.bottomRevision.originalDescriptorId,
        partName: this.sidePanel.bottomRevision.part,
        revisedTime: this.revisedTime,
        templateId: this.sidePanel.bottomRevision.templateId,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'Success') {
        this.impactedDescriptors = result.selected
      }
    });
  }

  editRevision() {
    const bottom = this.sidePanel.hasOwnProperty('bottomRevision') ? this.sidePanel.bottomRevision : null;

    const dialogRef = this.matDialog.open(EditRevisionComponent, {
      maxWidth: '90vw',
      minWidth: '280px',
      width: '748px',
      maxHeight: '100vh',
      autoFocus: false,
      disableClose: true,
      data: {
        partName: bottom ? bottom.part : this.sidePanelDataService.part,
        operationName: bottom ? bottom.operation : this.sidePanelDataService.operation,
        operationId: bottom ? bottom.operationId : this.sidePanelDataService.operationId,
        templateId: bottom ? bottom.templateId : this.sidePanelDataService.templateId,

        descriptorId: bottom ? bottom.originalDescriptorId : this.sidePanelDataService.descriptorId,

        expertId: this.currentUser.id,

        revisionId: (this.sidePanel.hasOwnProperty('bottomRevision') &&
                     this.sidePanel.bottomRevision.status === 'IN_PROGRESS' &&
                     this.sidePanel.bottomRevision.expertId === this.currentUser.id)
                    ? this.sidePanel.bottomRevision.id
                    : null,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'Success') {
        // Hide the start revision button and display the new data
        this.showStartRevision = false;
        this.revisionService.getRevision(result.revisionId)
          .subscribe(
            sidePanel => {
              this.initSidePanel(sidePanel);
              this.showEditRevision = this.showSubmitRevision = true;
            },
            () => this.messagingService.error('There was a problem loading the revision. Please try again.'));
      }
    });
  }

  submitRevision() {
    const dialogRef = this.matDialog.open(ConfirmationComponent, {
      disableClose: true,
      width: '275px',
      data: {
        message: 'Submit revision?',
        explanationText: 'You are about to submit your revision to our group of reviewers.' +
                         '\r\nYou will be notified of the outcome by email.',
        actionButton: 'Submit',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.revisionService.submitRevision(this.sidePanel.bottomRevision.id)
          .subscribe(
            () => {
              this.messagingService.confirm('Revision submitted.');
              this.showSubmitRevision = this.showEditRevision = false;
              this.revisionService.getRevision(this.sidePanel.bottomRevision.id)
                .subscribe(
                  sidePanel => {
                    this.sidePanel = sidePanel;
                  },
                  () => this.messagingService.error('There was a problem loading the revision. Please try again.'),
                );
            },
            () => this.messagingService.error('There was a problem saving the revision. Please try again.'),
          );
      }
    });
  }

  discardRevision() {
    const dialogRef = this.matDialog.open(ConfirmationComponent, {
      disableClose: true,
      width: '275px',
      data: {
        message: 'Discard revision?',
        actionButton: 'Discard',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.revisionService.discardRevision(this.sidePanel.bottomRevision.id)
          .subscribe(
            () => {
              this.messagingService.confirm('Revision discarded.');
              this.showEditRevision = this.showSubmitRevision = false;

              // We must clear only the discarded revision to be able to display any previous existing one
              this.sidePanelDataService.revisionId = null;
              this.router.navigate(['search']).catch();
              this.eventBus.bdClose();
            },
            () => this.messagingService.error('There was a problem discarding the revision. Please try again.'),
          );
      }
    });
  }

  approveRadioButtonChecked($event: MatCheckboxChange) {
    this.formType = $event.checked ? 'Approve' : 'BLANK';
    this.approveRejectForm.patchValue({
      approve: $event.checked ? $event.checked : false,
      reject: $event.checked ? !$event.checked : false,
    });
    this.note.setValidators(null);
    this.contingency.setValidators([Validators.required, Validators.pattern("[0-9]+")]);
    this.note.updateValueAndValidity();
    this.contingency.updateValueAndValidity();
  }

  rejectRadioButtonChecked($event: MatCheckboxChange) {
    this.formType = $event.checked ? 'Reject' : 'BLANK';
    this.approveRejectForm.patchValue({
      approve: $event.checked ? !$event.checked : false,
      reject: $event.checked ? $event.checked : false,
    });
    this.note.setValidators([Validators.required]);
    this.contingency.setValidators(null);
    this.note.updateValueAndValidity();
    this.contingency.updateValueAndValidity();
  }

  approveOrRejectButtonDisabled =
    () =>
      this.password.value === ''
      || this.formType === null
      || this.contingency.hasError('required')
      || this.contingency.hasError('pattern')
      || this.formType === 'Approve' && (this.contingency.value === null || this.contingency.value === '')
      || this.formType === 'Reject' && (this.note.value === null || this.note.value === '');

  approveOrRejectButtonClicked() {
    if (this.formType === 'Approve') {
      this.spinning = true;
      this.revisionService.approveRevision({
          contingency: Math.abs(this.contingency.value),
          contingencyUnit: this.contingencyUnit.value,
          note: this.note.value,
          password: this.password.value,
          revisionId: this.sidePanel.bottomRevision.id,
          selectedDescriptors: this.impactedDescriptors.map(d => d.descriptorId),
        } as ApproveRejectRevision)
        .subscribe(() => {
            this.messagingService.confirm('Revision published.');
            this.repairTime = null;
            sessionStorage.removeItem(this.cacheKey);
            if (this.sidePanelDataService.templateId !== null) {
              this.router.navigate(['search']).catch();
              this.shellService.setRefreshVehicle(true);
            } else {
              this.ngOnInit();
            }
          },
          (err) => this.displayErrorMessage(err, ' approving the revision'),
          () => this.spinning = false,
        );
    } else if (this.formType === 'Reject') {
      this.spinning = true;
      this.revisionService.rejectRevision({
          contingency: Math.abs(this.contingency.value),
          contingencyUnit: this.contingencyUnit.value,
          note: this.note.value,
          password: this.password.value,
          revisionId: this.sidePanel.bottomRevision.id,
          selectedDescriptors: this.impactedDescriptors.map(d => d.descriptorId),
        } as ApproveRejectRevision)
        .subscribe(() => {
            this.messagingService.confirm('Revision rejected.');
            this.repairTime = null;
            sessionStorage.removeItem(this.cacheKey);
            this.ngOnInit();
          },
          (err) => this.displayErrorMessage(err, ' rejecting the revision'),
          () => this.spinning = false,
        );
    }
  }

  displayErrorMessage(err, details: string = null) {
    this.spinning = false;
    if (err && err.errorCode && err.errorCode === 'invalid password') {
      this.unauthorized = 'Your password is not valid. Please try again.';
    } else {
      this.messagingService.error(`There was a problem${details}. Please try again.`);
    }
  }

  // TODO This will probably need changing when the list of archived revisions is available?
  toggleHistory(which: "TOP" | "BOTTOM") {
    if (!this.showHistory && this.historyElements == null) {
      this.revisionService.getRevisionHistory(which === "TOP"
                                              ? this.sidePanel.topRevision.id
                                              : this.sidePanel.bottomRevision.id)
        .subscribe(
          (result) => {
            this.historyElements = result;
            this.showHistory = true;
          },
          (err) => this.displayErrorMessage(err, ' loading revision history'),
        )
    } else {
      this.showHistory = !this.showHistory;
    }
  }

  openLightbox(revisionId: number, stepNumber: number) {
    this.lightboxService.open({
      image: {revisionId, stepNumber},
    });
  }

  isExpert = () => this.currentUser.privileges.includes('EXPERT');

  isExpertOwner = () => this.sidePanel.bottomRevision.expertId === this.currentUser.id;

  topCardExpandedInitially = () => (!this.sidePanel.topRevision && !this.sidePanel.bottomRevision) ||
                                   (this.sidePanelDataService.revisionId != null && this.sidePanel.topRevision &&
                         (this.sidePanel.topRevision.status === 'APPROVED' ||
                          this.sidePanel.topRevision.status === 'DISCARDED' ||
                          this.sidePanel.topRevision.status === 'ARCHIVED'));
}

interface ApproveRejectFormData {
  approve: boolean,
  contingency: number,
  contingencyUnit: "MINUTES" | "PERCENTAGE",
  note: string,
  reject: boolean,
  password: never
}
