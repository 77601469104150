import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeRoutingModule } from './welcome-routing.module';
import { HomeComponent } from './home/home.component';
import {
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatSidenavModule,
} from "@angular/material";
import { LoginComponent } from "../login/login.component";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RegisterComponent } from "../register/register.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    RegisterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatCardModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatPasswordStrengthModule,
    WelcomeRoutingModule,
    MatProgressSpinnerModule,
  ]
})
export class WelcomeModule { }
