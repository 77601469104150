import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessagingService } from "../components/messaging/messaging.service";
import * as i0 from "@angular/core";
import * as i1 from "../components/messaging/messaging.service";
import * as i2 from "@angular/router";
export class AuthInterceptor {
    constructor(messagingService, router) {
        this.messagingService = messagingService;
        this.router = router;
        this.intercept = (request, next) => next.handle(request)
            .pipe(catchError((error) => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
                this.messagingService.error('Your session has expired. Please log in again.');
                this.router.navigate(['welcome']).catch(() => window.location.replace('/'));
                return of(null);
            }
            throw error;
        }));
    }
}
AuthInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptor_Factory() { return new AuthInterceptor(i0.ɵɵinject(i1.MessagingService), i0.ɵɵinject(i2.Router)); }, token: AuthInterceptor, providedIn: "root" });
