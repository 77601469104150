import {Pipe, PipeTransform} from '@angular/core';
import {Part} from '../model/template.model';


@Pipe({name: 'filter'})
export class FilterPipe implements PipeTransform {

  transform(values: any, type: string, ...filters: any[]): any {

    switch (type) {

      case 'PARTS':

        // Part description
        if (filters[0]) {
          values = values.filter((part: Part) => part.description && part.description.toLowerCase().includes(filters[0].toLowerCase()));

        }

        // Section
        if (filters[1] && filters[1] !== 'All Sections') {
          values = values.filter((part: Part) => part.section === filters[1]);
        }

        return values;

      default:
        return values;
    }
  }

}

