import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'duration',
  pure: true,
})
export class DurationPipe implements PipeTransform {

  transform(duration: number, format: "hours" | "hh:mm") {

    if (format === "hours") {
      return (+duration / 60).toFixed(2);

    } else if (format === "hh:mm") {
      const h: number = Math.floor(duration / 60);
      const m: number = duration % 60;
      return `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
    }
  }
}
