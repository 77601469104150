<div class="header" mat-dialog-title>
  <div class="part-detail">
    {{partName}}
  </div>
  <h5>{{operationName}}</h5>
</div>
<div>
  <form [formGroup]="revisionForm">
    <div class="step" formArrayName="formSteps">
      <div *ngFor="let step of formSteps.controls; let i=index" [formGroupName]="i">
        <div *ngIf="!isSimpleRevision" class="edit-buttons">
          <div>{{step.get('stepNumber').value}}</div>
          <div class="buttons">
            <button (click)="showDescription[i] = true" *ngIf="!showDescription[i]" mat-icon-button type="button">
              <mat-icon>add</mat-icon>
              <mat-icon>sms</mat-icon>
            </button>
            <button (click)="selectImage.click()" *ngIf="!showImage[i]" class="right-button" mat-icon-button
                    type="button">
              <mat-icon>add</mat-icon>
              <mat-icon>camera_alt</mat-icon>
            </button>
            <input #selectImage (change)="displayFile($event, i)" [hidden]="true"
                   accept="image/jpeg, image/png"
                   type="file">
            <button [mat-menu-trigger-for]="stepMenu" class="right-button" mat-icon-button type="button">
              <mat-icon>more_horizontal</mat-icon>
            </button>
          </div>
        </div>
        <mat-menu #stepMenu xPosition="before">
          <button (click)="deleteStep(i)" [disabled]="formSteps.controls.length < 2" mat-menu-item type="button">Delete
            Step
          </button>
        </mat-menu>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap.gt-xs="20px">
          <div *ngIf="!isSimpleRevision" fxFlex="0 1 calc(75% - 20px)" fxFlex.xs="100%">
            <mat-form-field appearance="fill" color="accent">
              <mat-label>Title</mat-label>
              <input formControlName="title" matInput>
            </mat-form-field>
          </div>
          <div fxFlex="0 1 calc(25% - 20px)" fxFlex.xs="100%">
            <mat-form-field appearance="fill" color="accent">
              <mat-label>Time</mat-label>
              <mat-icon matSuffix>timer</mat-icon>
              <input (keyup)="formatTimeInput($event, i)" formControlName="time" matInput placeholder="hh:mm">
              <mat-error *ngIf="step.get('time').invalid">
                Format is hh:mm
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div [class.hidden]="!showImage[i]" [ngClass.gt-xs]="{'flex-width-image' : showDescription[i]}"
               class="image-wrapper" fxFlex="100%">
            <mat-grid-list [cols]="1" [rowHeight]="showImage[i] ? 148 : 0">
              <mat-grid-tile class="image">
                <mat-grid-tile-header>
                  <figcaption class="display-ellipsis">{{step.get('imageFile').value?.name}}</figcaption>
                  <mat-icon (click)="deleteImage(i)" class="delete-icon">delete_outline</mat-icon>
                </mat-grid-tile-header>
                <img *ngIf="showImage[i]" [src]="showImage[i]">
              </mat-grid-tile>
            </mat-grid-list>
          </div>
          <div *ngIf="showDescription[i]" [ngClass.gt-xs]="{'flex-width-description' : showImage[i]}"
               class="description"
               fxFlex="100%">
            <mat-form-field appearance="fill" color="accent">
              <mat-label>Description</mat-label>
              <mat-icon (click)="deleteDescription(i)" matSuffix>delete_outline</mat-icon>
              <textarea [cdkAutosizeMinRows]="showImage[i] && !isMobile ? 5 : 1" cdkTextareaAutosize
                        formControlName="description"
                        matInput></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div [class.no-margin]="showImage[currentStep -1] && !showDescription[currentStep - 1]" class="time-header"
           fxLayout="row" fxLayoutAlign="center">
        <div class="published" fxFlex="30%" fxFlex.xs="100%">
          <ng-container *ngIf="updatedTime">
            <div class="title">Total Time</div>
            <div class="times">
              <h6>{{updatedTime | duration:'hours'}}</h6>
              <div class="time-minutes">{{updatedTime | duration:'hh:mm'}}</div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
    <div *ngIf="!isSimpleRevision" class="step-button">
      <button (click)="addStep()" mat-button type="button">Add Step</button>
    </div>
    <div *ngIf="isSimpleRevision" class="step-button">
      <button (click)="addDetails()" mat-button type="button">Add Details</button>
    </div>
    <mat-dialog-actions class="footer" fxLayout="row" fxLayoutAlign="flex-start">
      <div *ngIf="isMobile" class="bottom-20" fxLayout="column" fxLayoutGap="20px">
        <button (click)="saveRevision()"
                [disabled]="!revisionForm.valid"
                color="accent" fxFlex="100%" mat-raised-button type="submit">
          <mat-icon>save</mat-icon>
          Save
        </button>
        <button fxFlex="100%" mat-button mat-dialog-close>Cancel</button>
      </div>
      <div *ngIf="!isMobile" fxLayoutGap="20px">
        <button fxFlex="0 1 (50% - 20px)" mat-button mat-dialog-close>Cancel</button>
        <button (click)="saveRevision()" [disabled]="!revisionForm.valid" color="accent" fxFlex="0 1 50%"
                mat-raised-button
                type="submit">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
