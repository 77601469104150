/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./confirmation.component";
var styles_ConfirmationComponent = [i0.styles];
var RenderType_ConfirmationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationComponent, data: {} });
export { RenderType_ConfirmationComponent as RenderType_ConfirmationComponent };
export function View_ConfirmationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h5", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(5, null, [" ", "\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 9, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(10, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dialogRef.close(i1.ɵnov(_v, 14).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(14, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(15, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_7 = ""; _ck(_v, 10, 0, currVal_7); var currVal_12 = true; _ck(_v, 14, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.confirmationMessage; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.explanationText; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 9).disabled || null); var currVal_4 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_6 = i1.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 13).disabled || null); var currVal_9 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); var currVal_10 = (i1.ɵnov(_v, 14).ariaLabel || null); var currVal_11 = i1.ɵnov(_v, 14).type; _ck(_v, 12, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_13 = _co.actionButtonText; _ck(_v, 15, 0, currVal_13); }); }
export function View_ConfirmationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-confirmation", [], null, null, null, View_ConfirmationComponent_0, RenderType_ConfirmationComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfirmationComponent, [i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationComponentNgFactory = i1.ɵccf("app-confirmation", i7.ConfirmationComponent, View_ConfirmationComponent_Host_0, {}, {}, []);
export { ConfirmationComponentNgFactory as ConfirmationComponentNgFactory };
