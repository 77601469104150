<div class="lightbox-container">
  <div [cdkDragDisabled]="isMobile" [cdkDragFreeDragPosition]="initialImagePosition" cdkDrag class="img-container">
    <img #imageElement (load)="loading = false" (wheel)="wheelScroll($event)"
         *ngIf="image.revisionId && image.stepNumber" [@fade]="loading ? 'fadeOut' : 'fadeIn'"
         src="/servlet/image?revisionId={{image.revisionId}}&stepNumber={{image.stepNumber}}">
  </div>

  <div class="tool-belt">
    <ng-container *ngIf="!isMobile">
      <mat-icon (click)="reset()">youtube_searched_for</mat-icon>
      <mat-icon (click)="zoomIn()">zoom_in</mat-icon>
      <mat-icon (click)="zoomOut()">zoom_out</mat-icon>
    </ng-container>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
</div>

<mat-spinner *ngIf="loading"></mat-spinner>

