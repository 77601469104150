/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./descriptor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./descriptor.component";
var styles_DescriptorComponent = [i0.styles];
var RenderType_DescriptorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DescriptorComponent, data: {} });
export { RenderType_DescriptorComponent as RenderType_DescriptorComponent };
function View_DescriptorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["+", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.count - 1); _ck(_v, 2, 0, currVal_0); }); }
export function View_DescriptorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "descriptor"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "vehicle"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " ", " ", ", ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DescriptorComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.count && ((_co.count - 1) > 0)); _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.descriptor == null) ? null : _co.descriptor.make); var currVal_1 = ((_co.descriptor == null) ? null : _co.descriptor.modelVariant); var currVal_2 = ((_co.descriptor == null) ? null : _co.descriptor.bodyType); var currVal_3 = (((_co.descriptor == null) ? null : _co.descriptor.yearEnd) ? ((("from " + ((_co.descriptor == null) ? null : _co.descriptor.yearStart)) + " to ") + ((_co.descriptor == null) ? null : _co.descriptor.yearEnd)) : ("since " + ((_co.descriptor == null) ? null : _co.descriptor.yearStart))); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_DescriptorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-descriptor", [], null, null, null, View_DescriptorComponent_0, RenderType_DescriptorComponent)), i1.ɵdid(1, 49152, null, 0, i3.DescriptorComponent, [], null, null)], null, null); }
var DescriptorComponentNgFactory = i1.ɵccf("app-descriptor", i3.DescriptorComponent, View_DescriptorComponent_Host_0, { descriptor: "descriptor", count: "count" }, {}, []);
export { DescriptorComponentNgFactory as DescriptorComponentNgFactory };
