import { BackdropTransition } from "./event-bus.types";
import { Subject } from "rxjs";
import * as i0 from "@angular/core";
export class EventBusService {
    constructor() {
        this.backdropTransition = new Subject();
        this.bdOpen = () => this.backdropTransition.next(BackdropTransition.OPENED);
        this.bdClose = () => this.backdropTransition.next(BackdropTransition.CLOSED);
    }
}
EventBusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventBusService_Factory() { return new EventBusService(); }, token: EventBusService, providedIn: "root" });
