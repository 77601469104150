import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  openSidebar = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  resetRouter() {
    if (this.openSidebar) {
      // Reset the named outlet to null to remove the special url from the route
      this.router.navigate(['welcome/', { outlets: { side: null}}]).catch();
    }
  }
}
