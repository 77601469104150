import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from '../login/login.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {HomeComponent} from './home/home.component';
import {RegisterComponent} from '../register/register.component';
import {SetPasswordComponent} from '../register/set-password.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: HomeComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        outlet: 'side'
      },
      {
        path: 'register',
        component: RegisterComponent,
        outlet: 'side'
      },
      {
        path: '',
        component: WelcomeComponent
      }
    ]
  },
  {
    path: 'setPassword/:nonce/:email/:name/:expiry',
    component: SetPasswordComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WelcomeRoutingModule {
}
