import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { LightboxComponent } from "./lightbox.component";
import { LightboxRef } from "./lightbox-ref";
import { IMAGE_TOKEN } from './lightbox.tokens';

const lightboxConfig: LightboxConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
  image: null,
};

@Injectable()
export class LightboxService {

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  open(config: LightboxConfig = {}) {
    // Override default configuration
    const boxConfig = {...lightboxConfig, ...config};
    const overlayRef = this.createOverlay(boxConfig);

    // Instantiate remote control
    const boxRef = new LightboxRef(overlayRef);

    this.attachBoxContainer(overlayRef, boxConfig, boxRef);
  }

  private createOverlay(config: LightboxConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private getOverlayConfig(config: LightboxConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
    });

    return overlayConfig;
  }

  private createInjector(config: LightboxConfig, boxRef: LightboxRef): PortalInjector {

    const injectionTokens = new WeakMap();
    injectionTokens.set(LightboxRef, boxRef);
    injectionTokens.set(IMAGE_TOKEN, config.image);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private attachBoxContainer(overlayRef: OverlayRef, config: LightboxConfig, boxRef: LightboxRef) {
    const injector = this.createInjector(config, boxRef);
    const containerPortal = new ComponentPortal(LightboxComponent, null, injector);
    overlayRef.attach(containerPortal);
  }
}

interface LightboxConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  image?: Image;
}

export interface Image {
  revisionId: number;
  stepNumber: number;
}


