<div mat-dialog-title class="header">
  <h5>Add Missing Time</h5>
</div>

<div mat-dialog-content class="content">
  <div class="guidance">
    This is the list of replace operations without time for this group of vehicles.
  </div>

  <div class="subheading">Applies to vehicles similar to</div>

  <div class="descriptor-a">
    <app-descriptor [descriptor]="template"></app-descriptor>
  </div>

  <ng-container *ngIf="missingParts.length; else noMissingTimes">

    <form [formGroup]="partsFilterForm">
      <mat-form-field appearance="fill" class="margin-top-0" color="accent">
        <mat-label>Filter parts</mat-label>
        <input (input)="selectedOperation = null" formControlName="descriptionFilter" matInput>
        <mat-icon (click)="descriptionFilter.value && descriptionFilter.setValue('')" class="cursor-pointer"
                  matSuffix>{{descriptionFilter.value ? 'cancel' : 'filter_list'}}</mat-icon>
      </mat-form-field>
    </form>

    <div *ngFor="let part of missingParts | filter:'PARTS':descriptionFilter.value" class="part-group-list">
      <div class="parts-group">
        <div >
          <div class="section"> {{part?.section}}</div>
          <div class="description"> {{part?.description}}</div>
        </div>
        <div class="count-operations">
          {{part.operations.length}} operations
        </div>
      </div>
      <div *ngFor="let operation of part.operations" class="operation-list " [class.selected]="operation.id === selectedOperation?.id">
        <div (click)="selectedOperation = operation; selectedPart = part;">
          <div class="bottom-line">{{operation.description}}</div>
        </div>
        <div><mat-icon *ngIf="operation.id === selectedOperation?.id">check</mat-icon></div>
      </div>
    </div>
    <div
      *ngIf="missingParts?.length && (missingParts | filter:'PARTS':descriptionFilter.value).length === 0"
      class="no-result">
      <h4>No results found</h4>
    </div>
  </ng-container>

  <ng-template #noMissingTimes>
    <div class="no-result margin-top-20">
      <h4>No missing times</h4>
      <div class="no-result-text">All available times are complete for this vehicle</div>
    </div>
  </ng-template>
</div>

<mat-dialog-actions>
  <mat-card
    *ngIf="(selectedOperation?.existingRevision?.reserved && selectedOperation?.existingRevision?.status === 'IN_PROGRESS') || selectedOperation?.existingRevision?.status === 'SUBMITTED'"
    class="explanation-container mat-elevation-z1">
    <mat-icon color="accent" matPrefix>error_outline</mat-icon>
    <div *ngIf="selectedOperation?.existingRevision?.status === 'IN_PROGRESS'" class="explanation">
      {{selectedOperation.existingRevision?.expertId === currentUser.id ? 'Your' : 'A'}} revision is in progress and
      reserved until {{selectedOperation?.existingRevision?.reservedUntilDate | date}}.
    </div>
    <div *ngIf="selectedOperation?.existingRevision?.status === 'SUBMITTED'" class="explanation">
      {{selectedOperation.existingRevision?.expertId === currentUser.id ? 'Your' : 'A'}} revision is submitted.
    </div>
  </mat-card>
  <mat-card
    *ngIf="selectedOperation?.existingRevision?.status === 'IN_PROGRESS' && !selectedOperation?.existingRevision?.reserved"
    class="explanation-container expired mat-elevation-z1">
    <mat-icon color="warn" matPrefix>error_outline</mat-icon>
    <div *ngIf="selectedOperation.existingRevision?.expertId === currentUser.id" class="explanation">
      Your revision is in progress but is available to everyone. Please submit as soon as possible.
    </div>
    <div *ngIf="selectedOperation.existingRevision?.expertId !== currentUser.id" class="explanation">
      A revision is in progress but has expired.
    </div>
  </mat-card>

  <button (click)="startRevision()"
          *ngIf="missingParts.length && !(selectedOperation?.existingRevision?.expertId === currentUser.id && selectedOperation?.existingRevision?.status === 'IN_PROGRESS')"
          [disabled]="!selectedOperation || (selectedOperation.existingRevision?.expertId !== currentUser.id && selectedOperation.existingRevision?.reserved) || selectedOperation.existingRevision?.status === 'SUBMITTED'"
          color="accent"
          mat-raised-button>
    <mat-icon>rate_review_2</mat-icon>
    START REVISION
  </button>
  <button (click)="startRevision()"
          *ngIf="missingParts.length && (selectedOperation?.existingRevision?.expertId === currentUser.id && selectedOperation?.existingRevision?.status === 'IN_PROGRESS')"
          mat-button>CONTINUE EDITING
  </button>

  <button mat-button mat-dialog-close class="margin-top-20">Cancel</button>

</mat-dialog-actions>
