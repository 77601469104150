import {HttpUrlEncodingCodec} from '@angular/common/http';

export class AARTParameterCodec extends HttpUrlEncodingCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key)
      .replace(/\+/g, '%2b');
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value)
      .replace(/\+/g, '%2b');
  }
}
