import {Injectable} from "@angular/core";
import {User} from "../../model/user.model";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class UserUpdateService {

  currentUser: User = null;

  private updatedUserSource = new BehaviorSubject(null);
  updatedUserObservable = this.updatedUserSource.asObservable();

  constructor() {}

  setUpdatedUser(user: User) {
    this.updatedUserSource.next(user);
  }
}
