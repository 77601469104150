/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./upload-progress.component";
import * as i6 from "@angular/material/dialog";
var styles_UploadProgressComponent = [i0.styles];
var RenderType_UploadProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadProgressComponent, data: {} });
export { RenderType_UploadProgressComponent as RenderType_UploadProgressComponent };
export function View_UploadProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Saving..."])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(3, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 3).mode === "indeterminate") || (i1.ɵnov(_v, 3).mode === "query")) ? null : i1.ɵnov(_v, 3).value); var currVal_1 = i1.ɵnov(_v, 3).mode; var currVal_2 = i1.ɵnov(_v, 3)._isNoopAnimation; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_UploadProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-progress", [], null, null, null, View_UploadProgressComponent_0, RenderType_UploadProgressComponent)), i1.ɵdid(1, 114688, null, 0, i5.UploadProgressComponent, [i6.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadProgressComponentNgFactory = i1.ɵccf("app-upload-progress", i5.UploadProgressComponent, View_UploadProgressComponent_Host_0, {}, {}, []);
export { UploadProgressComponentNgFactory as UploadProgressComponentNgFactory };
