import { Revision } from "../../model/revision.model";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApproveRejectRevision } from "../../model/ApproveRejectRevision.model";
import { RevisionHistory } from "../../model/revision-history.model";
import { RevisionDescriptor } from "../../model/descriptor.model";
import { LogRequestSplittingTime } from "../../model/logRequestSplittingTime";
import { RevisionTop, SidePanel } from "../../model/coreRevision.model";

@Injectable()
export class RevisionService {
  private _options = {headers: new HttpHeaders().set('Content-Type', 'application/json')};

  constructor(private _http: HttpClient) {
    // TODO Remove this
    // @ts-ignore
    this._options.responseType = 'json';
  }

  private static handleError(error: any) {
    return Promise.reject(error.error || error.message || error);
  }

  saveRevision(revision: Revision) {
    return this._http.post<number>(`api/revision`, revision, this._options)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  getRevision(revisionId: number) {
    return this._http.get<SidePanel>(`api/revision/${revisionId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  getLatestRevision = (descriptorId: number, templateRepairTimeId: number) =>
    this._http.get<SidePanel>(`api/revision/latest/${descriptorId}/${templateRepairTimeId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );

  getRevisionForEdit(revisionId: number) {
    return this._http.get<Revision>(`api/revisionForEdit/${revisionId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  getImpactedDescriptorsForTemplate = (templateId: number, operationId: number) =>
    this._http.get<RevisionDescriptor[]>(`api/impactedDescriptors/${templateId}/${operationId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );

  getSiblingDescriptors = (templateId: number, operationId: number) =>
    this._http.get<RevisionDescriptor[]>(`api/siblingDescriptors/${templateId}/${operationId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );

  getImpactedDescriptorsForRevision = (revisionId: number) =>
    this._http.get<RevisionDescriptor[]>(`api/impactedDescriptors/${revisionId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );

  approveRevision(approval: ApproveRejectRevision) {
    return this._http.post(`api/revision/approve`, approval, this._options)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  rejectRevision(rejection: ApproveRejectRevision) {
    return this._http.post(`api/revision/reject`, rejection, this._options)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  uploadRevisionImages(revisionId: number, images: FormData) {
    const options = {headers: new HttpHeaders({enctype: 'multipart/form-data'})};
    return this._http.post(`servlet/image?revisionId=${revisionId}`, images, options)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  submitRevision(revisionId: number) {
    return this._http.post(`api/revision/submit/${revisionId}`, null, this._options)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  discardRevision(revisionId: number) {
    return this._http.post(`api/revision/discard/${revisionId}`, null, this._options)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  getRevisionHistory(revisionId: number) {
    return this._http.get<RevisionHistory[]>(`api/revision/history/${revisionId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  logRequestSplittingTime(request: LogRequestSplittingTime) {
    return this._http.post(`api/revision/logRequestSplittingTime`, request, this._options)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }

  getArchivedRevisions(descriptorId: number, operationId: number) {
    return this._http.get<RevisionTop[]>(`api/revisions/archived/${descriptorId}/${operationId}`)
      .pipe(
        catchError(RevisionService.handleError),
      );
  }
}
