import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SearchComponent} from "./search/search.component";
import {ShellComponent} from "./shell/shell.component";
import {UsersComponent} from "./users/users.component";
import {UpdateUserComponent} from "./users/update-user.component";
import {RevisionComponent} from "./revision/revision.component";
import {RevisionsComponent} from "./revisions/revisions.component";

const routes: Routes = [
  {
    path: 'search',
    component: ShellComponent,
    children: [
      {
        path: 'users',
        component: UsersComponent,
      },
      {
        path: 'update',
        component: UpdateUserComponent,
        outlet: 'side'
      },
      {
        path: 'revision',
        component: RevisionComponent,
        outlet: 'side'
      },
      {
        path: 'revisions',
        component: RevisionsComponent,
      },
      {
        path: '',
        component: SearchComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchRoutingModule { }
