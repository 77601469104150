<div class="page-container">
  <mat-card class="header-image-card">
    <mat-card-content class="header-background-image">
      <div>IT'S</div>
      <div>ABOUT</div>
      <div>TIME</div>
    </mat-card-content>
  </mat-card>
  <mat-card class="login-band">
    <mat-card-header fxLayout="row">
      <div mat-card-avatar class="logo-image"></div>
      <mat-card-title>
        <h6>Australian Automotive Repair Times</h6>
      </mat-card-title>
      <div fxFlex></div>
      <div class="login-button-wrapper" fxLayoutAlign="right">
        <button mat-button (click)="login()">LOG IN</button>
      </div>
     </mat-card-header>
  </mat-card>
  <mat-divider class="mat-divider"></mat-divider>
  <mat-grid-list [cols]="isMobile ? 1 : isTablet ? 2 : 3" [rowHeight]="isMobile ? 200 : 180" [gutterSize]="2">
    <mat-grid-tile [colspan]="isMobile || isTablet ? 1 : 2">
      <div class="description-block">
        <h6 [class.mobile]="isMobile">AART provides peer-certified repair times for the Australian automotive industry.</h6>
        <div [class.mobile]="isMobile">Access to a constantly updated database reviewed by seasoned professionals. Get accurate data or request updates for times you know need a second look.</div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="register-card mat-elevation-z2" [class.margin-left-10]="!isTablet && !isMobile">
        <h5 class="register">Register</h5>
        <div class="register-text">AART is free for everyone.</div>
        <mat-card-actions align="center">
          <button mat-raised-button color="accent" (click)="register()">REGISTER</button>
        </mat-card-actions>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  <mat-grid-list class="images-list" [cols]="isMobile ? 1 : 3" [gutterSize]="20" [rowHeight]="300">
    <mat-grid-tile>
      <mat-card class="image-card mat-elevation-z0" [class.mobile-height]="isMobile">
        <img class="image-in-card" mat-card-image src="../../../assets/images/AccurateTimes.png">
        <mat-card-content>
          <h6>Accurate times</h6>
          <p>
            For all car manufacturers in Australia and always up-to-date.
          </p>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="image-card mat-elevation-z0" [class.mobile-height]="isMobile">
        <img class="image-in-card" mat-card-image src="../../../assets/images/TransparentReview.png">
        <mat-card-content>
          <h6>Transparent Review</h6>
          <p>
            Done by experts with years of experience, our review process is fully transparent.
          </p>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
      <mat-card class="image-card mat-elevation-z0" [class.mobile-height]="isMobile">
        <img class="image-in-card" mat-card-image src="../../../assets/images/HaveYourSay.png">
        <mat-card-content>
          <h6>Have your say</h6>
          <p>
            Ask for a time to be reviewed or participate in the review process and get paid.
          </p>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<div class="footer-container">
  <a href="https://www.stelvio.com/" target="_blank">
    <div class="logo-grey"></div>
  </a>
  <div class="footer-text">All contents copyright 1990-{{currentYear}} Stelvio Inc.</div>
</div>
