import { Directive, HostListener } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[mat-raised-button]',
})
export class JwtRefreshDirective {

  constructor(
    private auth: AuthService,
  ) {}

  @HostListener('click', ['$event'])
  onClick() {
    this.auth.refresh().subscribe();
  }
}
