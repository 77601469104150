import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Revision, Step } from "../../model/revision.model";
import { MessagingService } from "../../components/messaging/messaging.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { UploadProgressComponent } from "../upload-progress/upload-progress.component";
import { DurationPipe } from "../../pipes/duration.pipe";
import { RevisionService } from "./revision.service";
import { SidePanelBus } from "./side-panel-bus.service";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
  selector: 'app-edit-revision',
  templateUrl: './edit-revision.component.html',
  styleUrls: ['./edit-revision.component.scss'],
  providers: [RevisionService, DurationPipe],
  encapsulation: ViewEncapsulation.None,
})
export class EditRevisionComponent implements OnInit {
  partName: string;
  operationName: string;
  expertId: number;
  updatedTime: number;
  isMobile = false;
  showDescription: boolean[] = [];
  showImage = [];
  revisionForm: FormGroup;
  steps: Step[];
  hasImage = false;
  revisionId: number;
  currentStep = 0;
  maxImageSize = 10; // Max size in MB
  revision: Revision;
  isSimpleRevision = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private breakpointObserver: BreakpointObserver,
    private durationPipe: DurationPipe,
    private fb: FormBuilder,
    private messagingService: MessagingService,
    private revisionService: RevisionService,
    public dialogRef: MatDialogRef<EditRevisionComponent>,
    public progressDialog: MatDialog,
    private sidePanelDataService: SidePanelBus,
    private sanitizer: DomSanitizer,
  ) { }

  get formSteps() {return this.revisionForm.get('formSteps') as FormArray; }

  ngOnInit() {
    this.partName = this.data.partName;
    this.operationName = this.data.operationName;
    this.expertId = this.data.expertId;
    this.revisionId = this.data.revisionId;

    this.revisionForm = new FormGroup({
      formSteps: new FormArray([]),
    });

    if (this.revisionId) {
      this.getRevisionForEdit();
    } else {
      this.createSimpleStep();
      this.isSimpleRevision = true;
    }

    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
      .subscribe(result => {
        this.isMobile = result.breakpoints[Breakpoints.HandsetPortrait];
      });

    this.formSteps.valueChanges.pipe(debounceTime(750), distinctUntilChanged()).subscribe(() => this.sumTimes());
  }

  getRevisionForEdit() {
    this.revisionService.getRevisionForEdit(this.revisionId)
      .subscribe(
        revision => this.revision = revision,
        () => this.messagingService.error('There was a problem loading the revision. Please try again.'),
        () => {
          this.isSimpleRevision = this.revision.steps.length === 1 && !this.revision.steps[0].stepNumber;
          this.populateSteps();
        },
      );
  }

  sumTimes() {
    let timeSum = 0;
    this.formSteps.controls.forEach(step => {
      timeSum += this.convertToMinutes(step.get('time').value.trim());
    });
    this.updatedTime = timeSum;
  }

  formatTimeInput($event: any, index: number) {
    let formatted = null;
    if ($event.code !== 'Backspace' && $event.target.value !== "") {
      const value = parseInt($event.target.value.replace(":", ""), 10).toString();
      if (value !== 'NaN') {
        switch (value.length) {
          case 0:
            formatted = '0:00';
            break;
          case 1:
            formatted = `0:0${value}`;
            break;
          case 2:
            formatted = `0:${value}`;
            break;
          default:
            formatted = value.slice(0, -2) + ":" + value.slice(-2);
        }
      }
    }
    if (formatted) {
      this.formSteps.controls[index].get('time').setValue(formatted);
      this.formSteps.controls[index].get('time').markAsTouched();
    }
  }

  createSimpleStep() {
    this.formSteps.push(
      this.fb.group({
        stepNumber: null,
        title: null,
        time: ['', [Validators.pattern(/^([0-9]?\d):[0-5]\d$/)]],
        description: null,
        imageFile: null,
      }),
    );
  }

  populateSteps() {
    this.revision.steps.forEach(step => {
      step.description ? this.showDescription.push(true) : this.showDescription.push(false);
      step.containsImage
      ? this.showImage.push(`servlet/image?revisionId=${this.revisionId}&stepNumber=${step.stepNumber}`)
      : this.showImage.push(null);
      const stepControl = this.fb.group({
        stepNumber: step.stepNumber,
        title: [step.title],
        time: [step.repairTimeMinutes ? this.durationPipe.transform(step.repairTimeMinutes, 'hh:mm') : '',
          [Validators.pattern(/^([0-9]?\d):[0-5]\d$/)]],
        description: [step.description],
        id: [step.id],
        imageFile: [null], // No need to save an image already on the server
        containsImage: step.containsImage,
      });
      this.formSteps.push(stepControl);
    });
    this.currentStep = this.revision.steps.length;
  }

  initStep() {
    this.currentStep++;
    this.showDescription.push(false);
    this.showImage.push(null);
    return this.fb.group({
      stepNumber: this.currentStep,
      title: [''],
      time: ['', [Validators.pattern(/^([0-9]?\d):[0-5]\d$/)]],
      description: [''],
      imageFile: [null],
      containsImage: [false],
    });
  }

  addDetails() {
    // Transform the simple step keeping the time that can have already been entered
    const time = this.formSteps.controls[0].get('time').value;
    this.formSteps.clear();
    this.currentStep = 0; // We are changing the nature of the current step, not creating a new one.
    this.addStep();
    this.formSteps.controls[0].get('time').setValue(time);
    this.isSimpleRevision = false;
  }

  addStep() {
    const control = this.revisionForm.controls.formSteps as FormArray;
    const stepControl = this.initStep();
    control.push(stepControl);
  }

  deleteStep(index: number) {
    this.formSteps.controls.splice(index, 1);
    this.showDescription.splice(index, 1);
    this.showImage.splice(index, 1);

    // Next step number should change too
    this.currentStep--;

    // Reorder the steps
    let stepNumber = 1;
    this.formSteps.controls.forEach(step => step.get('stepNumber').setValue(stepNumber++));
  }

  deleteDescription(index: number) {
    this.showDescription[index] = false;
    this.formSteps.controls[index].get('description').setValue('');
  }

  deleteImage(index: number) {
    this.showImage[index] = null;
    this.formSteps.controls[index].get('imageFile').setValue(null);
    if (this.formSteps.controls[index].get('containsImage')) {
      this.formSteps.controls[index].get('containsImage').setValue(false);
    }
    this.formSteps.controls.forEach(step => {
      this.hasImage = false;
      if (step.get('imageFile').value) {
        this.hasImage = true;
        return;
      }
    });
  }

  displayFile($event, index) {
    this.formSteps.controls[index].get('imageFile').setValue($event.target.files[0]);
    this.formSteps.controls[index].get('containsImage').setValue(true);
    const imageFile = $event.target.files[0];
    const filename = imageFile.name;
    const imageSize = imageFile.size / 1024 / 1024; // In MB

    const extension = filename.split('.').pop().toLowerCase();
    if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
      this.messagingService.error('The image format must be jpg or png.');
    } else if (imageSize > this.maxImageSize) {
      this.messagingService.error('The image exceeds the maximum size (10 MB).');
    } else {
      const imgURL = URL.createObjectURL(imageFile);
      this.showImage[index] = this.sanitizer.bypassSecurityTrustUrl(imgURL);
      this.hasImage = true;
    }
  }

  saveRevision() {
    const progressDialogRef = this.progressDialog.open(UploadProgressComponent, {
      width: '250px',
      disableClose: true,
    });

    this.steps = [];
    this.formSteps.controls.forEach(
      step => {
        this.steps.push({
          stepNumber: step.get('stepNumber').value,
          title: step.get('title').value ? step.get('title').value.trim() : null,
          repairTimeMinutes: step.get('time').value ? this.convertToMinutes(step.get('time').value.trim()) : null,
          description: step.get('description').value ? step.get('description').value : null,
          id: step.get('id') ? step.get('id').value : null,
          containsImage: step.get('containsImage') ? step.get('containsImage').value :
                         step.get('imageFile').value !== null,
        } as Step);
      },
    );

    // Remove the empty steps
    this.steps = this.steps.filter((step: Step) =>
      !((!step.title || step.title.length === 0) && !step.repairTimeMinutes && !step.description &&
        !step.containsImage));

    // If there is only one step with only a time, make it a simple revision by removing the step number
    if (this.steps.length === 1 && this.steps[0].repairTimeMinutes &&
        (!this.steps[0].title || this.steps[0].title.length === 0) && !this.steps[0].description &&
        (!this.steps[0].containsImage && !this.formSteps.controls[0].get('imageFile'))) {
      this.steps[0].stepNumber = null;
    }

    this.revisionService.saveRevision(
      {
        operationId: this.revision ? this.revision.operationId : this.data.operationId,
        originalDescriptorId: this.revision ? this.revision.originalDescriptorId : this.data.descriptorId,
        templateId: this.revision ? this.revision.templateId : this.data.templateId,
        expertId: this.expertId,
        steps: this.steps,
      } as Revision,
    ).subscribe(
      revisionId => {

        this.sidePanelDataService.clear();
        this.sidePanelDataService.operation = this.operationName;
        this.sidePanelDataService.part = this.partName;

        this.revisionId = revisionId;
        if (this.hasImage) {
          this.saveImage(progressDialogRef);
        } else {
          progressDialogRef.close();
          this.dialogRef.close({status: 'Success', revisionId: this.revisionId});
        }
      },
      () => {
        progressDialogRef.close();
        this.messagingService.error('There was a problem saving the revision. Please try again.');
      },
    );
  }

  saveImage(progressDialogRef) {
    const formData: FormData = new FormData();
    this.formSteps.controls.forEach(
      step => {
        const imageFile = step.get('imageFile').value;
        if (imageFile) {
          // This will only save new images. To update one, the user must delete and upload a new image, anyway
          const extension = imageFile.name.split('.').pop().toLowerCase();
          formData.append('Image' + step.get('stepNumber').value, imageFile, step.get('stepNumber').value +
                                                                             '.' +
                                                                             extension);
          this.revisionService.uploadRevisionImages(this.revisionId, formData)
            .subscribe(
              () => {
                progressDialogRef.close();
                this.dialogRef.close({status: 'Success', revisionId: this.revisionId});
              },
              () => {
                progressDialogRef.close();
                this.messagingService.error('There was a problem uploading images. Please try again.');
              },
            );
        }
      },
    )
  }

  convertToMinutes(value: string) {
    const hoursMinutes = value.split(':');
    return (+hoursMinutes[0] * 60) + +hoursMinutes[1];
  }

}
