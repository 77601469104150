import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtRefreshDirective } from './jwt-refresh.directive';
import { AuthService } from '../auth/auth.service';

/**
 * Include this module anywhere you want automatic JWT Token Refresh.
 */
@NgModule({
  declarations: [
    JwtRefreshDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    JwtRefreshDirective,
  ],
  providers: [
    AuthService,
  ],
})
export class JwtRefreshModule {}
