import * as i0 from "@angular/core";
export class SidePanelBus {
    constructor() {
        this.clear = () => {
            this.descriptorId = null;
            this.operationId = null;
            this.revisionId = null;
            this.templateId = null;
            this.templateRepairTimeId = null;
            this.operation = null;
            this.part = null;
        };
    }
}
SidePanelBus.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SidePanelBus_Factory() { return new SidePanelBus(); }, token: SidePanelBus, providedIn: "root" });
