import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ShellService } from "../shell/shell.service";
import { SearchService } from './search.service';
import { Part, RepairTime, Template } from '../../model/template.model';
import { Vehicle } from '../../model/vehicle.model';
import { MessagingService } from '../../components/messaging/messaging.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { SidePanelBus } from '../revision/side-panel-bus.service';
import { EventBusService } from "../../helpers/event-bus/event-bus.service";
import { BackdropTransition } from "../../helpers/event-bus/event-bus.types";
import { AARTMediaQueries, CalculateColumnSize, ColumnSizeCSS, CountColumns } from "../../helpers/breakpoints";
import { MatDialog } from "@angular/material/dialog";
import { AddMissingTimeComponent } from "../add-missing-time/add-missing-time.component";
import { User } from "../../model/user.model";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['../search.component.scss'],
  providers: [SearchService],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {

  currentUser: User = null;

  searchForm: FormGroup;
  searchCriteria = {make: '', model: '', year: '', layer: ''};
  searching = false;

  columnSize: ColumnSizeCSS = '0 1 calc(25% - 20px)';
  columnCount: number;
  columnFrame: ColumnName[] = ["SEARCH"];

  get make() {
    return this.searchForm.get('make').value;
  }

  get model() {
    return this.searchForm.get('model').value;
  }

  get year() {
    return this.searchForm.get('year').value;
  }

  paintLayer = '3';
  detailPaintLayer = '';

  makes: string[] = [];
  years: (number | 'All Years')[] = [];

  vehicles: Vehicle[] = [];
  selectedVehicle: Vehicle = null;

  sections = ['All Sections', 'Front', 'Centre', 'Rear', 'Trim/Glass'];

  selectedPart: Part = null;
  selectedRepairTime: RepairTime = null;
  searchingParts = false;
  toggleDetailPaintLayers = false;

  template: Template;

  partsFilterForm: FormGroup;

  get descriptionFilter() {
    return this.partsFilterForm.get('descriptionFilter');
  }

  get sectionFilter() {
    return this.partsFilterForm.get('sectionFilter').value;
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private eventBus: EventBusService,
    private fb: FormBuilder,
    private searchService: SearchService,
    private messagingService: MessagingService,
    private router: Router,
    private sidePanelDataService: SidePanelBus,
    public matDialog: MatDialog,
    private shellService: ShellService,
  ) {}

  @HostListener('document:click', ['$event'])
  public onClick(event) {
    const isOutside = !event.target.className.includes('layers-select-box')
                      && !event.target.parentElement.className.includes('paint-layer-icon');
    if (isOutside && this.toggleDetailPaintLayers) {
      this.toggleDetailPaintLayers = false;
    }
  }

  ngOnInit() {
    this.searchForm = this.fb.group({
      make: ['All Makes'],
      model: [''],
      year: ['All Years'],
    });

    this.getMakes();
    this.getYears();

    this.partsFilterForm = this.fb.group({
      descriptionFilter: '',
      sectionFilter: 'All Sections',
    });

    this.breakpointObserver.observe(AARTMediaQueries)
      .subscribe(state => {
        this.columnCount = CountColumns(state);
        this.columnSize = CalculateColumnSize(state);
      });

    this.eventBus.backdropTransition.subscribe(
      transition => {
        if (transition === BackdropTransition.CLOSED) {
          this.selectedRepairTime = null;
        }
      },
      () => {},
      () => this.eventBus.backdropTransition.unsubscribe(),
    );

    this.shellService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    this.shellService.refreshVehicle.subscribe(refreshVehicle => {
      if (refreshVehicle && this.selectedVehicle !== null) {
        this.openParts(this.selectedVehicle);
        this.shellService.setRefreshVehicle(false);
      }
    });
  }

  getMakes() {
    this.searchService.getMakes()
      .subscribe(
        makes => {
          this.makes = makes;
          this.makes.unshift('All Makes');
        },
        () => this.messagingService.error('There was a problem loading vehicle makes. Please try again.'),
      );
  }

  getYears() {
    const currentYear = new Date().getFullYear();
    const since = 2005;
    const range = (start, stop, step) => Array.from({
      length: (stop - start) / step + 1,
    }, (_, i) => start + (i * step));
    this.years = range(currentYear + 1, since, -1);
    this.years.unshift('All Years');
  }

  search() {
    this.vehicles = [];
    this.template = null;
    this.selectedVehicle = null;
    this.selectedPart = null;
    this.detailPaintLayer = this.paintLayer;

    this.columnFrame = ['SEARCH'];
    this.dig('VEHICLES');
    this.searching = true;
    this.searchCriteria = {
      make: this.make === 'All Makes' ? '' : this.make,
      model: this.model,
      year: this.year === 'All Years' ? '' : this.year.toString(),
      layer: this.paintLayer,
    };

    this.searchService.search(this.searchCriteria)
      .subscribe(
        vehicles => {
          this.vehicles = vehicles;
          this.searching = false;
        },
        () => {
          this.messagingService.error('There was a problem searching for vehicles. Please try again.');
          this.searching = false;
        },
      );
  }

  clearFilters() {
    this.partsFilterForm.reset({
      descriptionFilter: '',
      sectionFilter: 'All Sections',
    });
  }

  openParts(vehicle: Vehicle) {
    this.selectedVehicle = vehicle;
    this.template = null;
    this.clearFilters();
    this.closeTimes();

    if (this.columnFrame.includes('TIMES')) {
      this.back('TIMES')
    }

    this.dig('PARTS');
    this.searchingParts = true;
    const criteria = {
      make: vehicle.make,
      description: vehicle.modelAndVariant,
      bodyStyle: vehicle.bodyStyle,
      year: vehicle.year.toString(),
    };

    this.searchService.getTimes(criteria)
      .subscribe(
        template => {
          this.template = template;
          this.searchingParts = false;
        },
        () => {
          this.messagingService.error('There was a problem loading times. Please try again.');
          this.searchingParts = false;
        },
      );
  }

  closeParts() {
    this.selectedVehicle = null;
    this.back('PARTS');
    this.closeTimes();
  }

  openTimes(part: Part) {
    this.dig('TIMES');
    this.selectedPart = part;
  }

  closeTimes() {
    this.back('TIMES');
    this.selectedPart = null;
    this.toggleDetailPaintLayers = false;
  }

  showRevision(repairTime: RepairTime, descriptorId: number) {
    this.selectedRepairTime = repairTime;
    this.sidePanelDataService.clear();
    this.sidePanelDataService.descriptorId = descriptorId;
    this.sidePanelDataService.operationId = repairTime.operationId;
    this.sidePanelDataService.templateId = repairTime.templateId;
    this.sidePanelDataService.templateRepairTimeId = repairTime.id;

    this.sidePanelDataService.operation = repairTime.description;
    this.sidePanelDataService.part = this.selectedPart.description;
    this.router.navigate(['search', {outlets: {side: 'revision'}}]).catch();
  }

  addMissingTime(partDescription?: string) {
    const dialogRef = this.matDialog.open(AddMissingTimeComponent, {
      width: '355px',
      height: '655px',
      autoFocus: false,
      disableClose: true,
      data: {
        template: this.template,
        currentUser: this.currentUser,
        descriptionFilter: partDescription ? partDescription : '',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.status === 'Success') {
        this.sidePanelDataService.revisionId = result.revisionId;
        this.router.navigate(['search', {outlets: {side: 'revision'}}]).catch();
      }
    });
  }

  dig = (to: ColumnName) => !this.columnFrame.includes(to) && this.columnFrame.push(to);

  back = (from: ColumnName) => this.columnFrame.includes(from) && this.columnFrame.pop();

  visible = (self: ColumnName) => (this.columnCount >= this.columnFrame.length)
                                  ? this.columnFrame.includes(self)
                                  : [...this.columnFrame].splice(-this.columnCount).includes(self);

  showBack = (self: ColumnName) => [...this.columnFrame].splice(-this.columnCount)[0] === self;

  comma = (...terms: string[]) => terms.filter(String).join(', ');
}

type ColumnName = "SEARCH" | "VEHICLES" | "PARTS" | "TIMES";
