import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'revisionStatus',
  pure: true
})
export class RevisionStatusPipe implements PipeTransform {

  transform(status) {
    // @ts-ignore
    switch (status) {
      case 'IN_PROGRESS': return 'In Progress';
      case 'SUBMITTED': return 'Submitted';
      case 'APPROVED': return 'Approved';
      case 'DISCARDED': return 'Discarded';
      case 'ARCHIVED': return 'Archived';
    }
  }
}
