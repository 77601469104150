import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserService } from "../../register/user.service";
import { MessagingService } from "../../components/messaging/messaging.service";
import { User } from "../../model/user.model";
import { Router } from "@angular/router";
import { UserUpdateService } from "./user-update.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { AARTMediaQueries, CalculateColumnSize, ColumnSizeCSS, CountColumns } from "../../helpers/breakpoints";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  searchUsersForm: FormGroup;

  privileges: string[] = ['Any', 'Admin', 'Basic', 'Expert', 'Reviewer'];
  searchCriteria = {user: '', privilege: ''};
  showResults = false;
  editedUser: User;
  users: User[] = [];

  columnSize: ColumnSizeCSS = '0 1 calc(25% - 20px)';
  columnCount: number;

  get user() {
    return this.searchUsersForm.get('user').value;
  }

  get privilege() {
    return this.searchUsersForm.get('privilege').value;
  }

  constructor(private breakpointObserver: BreakpointObserver,
              private fb: FormBuilder,
              private userService: UserService,
              private messagingService: MessagingService,
              private router: Router,
              private userUpdateService: UserUpdateService) {}

  ngOnInit() {
    this.searchUsersForm = this.fb.group({
      user: [''],
      privilege: ['Any'],
    });

    this.breakpointObserver.observe(AARTMediaQueries)
      .subscribe(state => {
        this.columnCount = CountColumns(state);
        this.columnSize = CalculateColumnSize(state);
      });

    this.userUpdateService.updatedUserObservable.subscribe(user => this.editedUser = user);
  }

  searchUsers() {
    this.searchCriteria = {
      user: this.user,
      privilege: this.privilege === 'Any'
                 ? '' : this.privilege === 'Basic'
                        ? 'NONE'
                        : (this.privilege as string).toUpperCase(),
    };
    this.userService.searchUsers(this.searchCriteria)
      .subscribe(
        users => {
          this.users = users;
          this.showResults = true;
        },
        () => {
          this.messagingService.error('There was a problem searching. Please try again.');
          this.showResults = false;
        },
      );
  }

  editSearchCriteria() {
    if (this.columnCount < 2) {
      this.showResults = false;
    }
  }

  showUpdateUser(user: User) {
    this.userUpdateService.setUpdatedUser(user);
    this.router.navigate(['search', {outlets: {side: 'update'}}]).catch();
  }

  comma = (terms: string[]) => terms.filter(String).join(', ');
}
